import { Box } from "@mui/material";
import Card from "react-bootstrap/Card";

interface CategoryCardProps {
  catKey: string;
  title: string;
  description: string;
  onClick: any;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  catKey,
  title,
  description,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(title);
    }
  };
  return (
    <Box pb={1}>
      <Card
        className="cursor-pointer category-card border h-100 card-bg-color"
        onClick={handleClick}
      >
        <Card.Body className="p-6">
          <Box className="border-1 border-bottom fs-3 fw-bolder mb-3 pb-2 text-primary">
            {title}
          </Box>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default CategoryCard;
