import axios from "axios";
import { log } from "console";
import CryptoJS from 'crypto-js';
import { refreshToken } from ".";

const instance = axios.create();

instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.timeout = 1000 * 30;


const encryptPayload = (payload: any, secretKey: any, iv: any) => {
  const key = CryptoJS.enc.Base64.parse(secretKey);
  const ivParsed = CryptoJS.enc.Base64.parse(iv);
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload), key, { iv: ivParsed }).toString();
  return ciphertext;
};

const decryptPayload = (ciphertext: any, secretKey: any, iv: any) => {
  const key = CryptoJS.enc.Base64.parse(secretKey);
  const ivParsed = CryptoJS.enc.Base64.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(ciphertext?.encryptedData, key, { iv: ivParsed }).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};

// request interceptor
instance.interceptors.request.use(
  (config) => {
    try {
      const shouldEncrypt =   localStorage.getItem('maintenanceData');
      if ((shouldEncrypt === "true" ) && config.method && config.method.toUpperCase() !== "GET" && !(config.data instanceof FormData)) {
        const encryptedPayload = encryptPayload(config.data, process.env.REACT_APP_SECRET_KEY, process.env.REACT_APP_IV);
        config.data = { encryptedData: encryptedPayload };
      }else{
        config.data = config.data ;
      }
    } catch (error) {
      console.error('Error in request interceptor:', error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);


// response interceptor
instance.interceptors.response.use(
  (response) => {
      const shouldEncrypt =   localStorage.getItem('maintenanceData');
    const responseData = response.data['data'];
    let decryptedResponseData = responseData;
    if ((shouldEncrypt === "true" ) && responseData) {
      decryptedResponseData = decryptPayload(responseData, process.env.REACT_APP_SECRET_KEY, process.env.REACT_APP_IV);
    }else{
      decryptedResponseData = responseData;
    }
    const responseDataDecrypt = {
      data: {
        data: decryptedResponseData,
        status:response.data['status'],
        message:response.data['message']
      },

    };
    return responseDataDecrypt;
  },
  async (error) => {  // Make the function async
    if (error.response && error.response.status === 401) {
      const expiredToken = localStorage.getItem('userToken');
      const refreshTokenValue = localStorage.getItem('refreshToken');
      const payloadData = {
        token:expiredToken,
        refreshToken: refreshTokenValue,
      };
      if (typeof refreshToken === 'function') {
        try {
          const response = await refreshToken(payloadData);
          if (response?.status) {
            localStorage.setItem('userToken', response?.data?.accessToken);
            window.location.reload();
          } else {
            localStorage.clear();
            window.location.reload();
          }
        } catch (err) {
          console.error('Error refreshing token:', err);
        }
      } else {
        console.error('refreshToken is not a function.');
      }
    }
    if (error.response && (error.response.status === 417 || error.response.status === 423)) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default instance;

function jsonDecode(arg0: any) {
  throw new Error("Function not implemented.");
}
