import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
import {
  addGolfClub,
  getCategories,
  getCities,
  getCountries,
  getStates,
  uploadPicture,
} from "../../api";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
  Box,
  Stack,
  Chip,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { multiSectionDigitalClockSectionClasses, TimePicker } from "@mui/x-date-pickers";
import { IEndTime, IGolf, IStartTime } from "../../constants/types";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { charValidate } from "../../components/utility";
import CancelIcon from '@mui/icons-material/Cancel';
import Select from 'react-select';
import { convertDayjsFormatTimeTo24HourFormat, convertToDayjsFormat } from "../../helpers/commonFunctions";
import httpsClient from "../../api/httpsClient";
import { APP_URLs } from "../../constants/appURLs";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: IGolf;
};

interface IHoursOfOperation {
  name: string;
  startTime: string;
  endTime: string;
}

interface IBusiestDay {
  startTime: string;
  endTime: string;
}

interface IHoursOfOperation {
  name: string;
  startTime: string;
  endTime: string;
}

interface IBusiestDay {
  startTime: string;
  endTime: string;
}

interface IDailySchedule {
  day: string;
  hoursOfOperationOne: IHoursOfOperation;
  busiestDayOne: IBusiestDay;
  IsOpen: boolean;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditGolf = ({ show, handleClose, editData }: Props) => {
  let imgUrl: string = editData?.picture
    ? editData?.picture[0]
      ? editData?.picture[0]
      : ""
    : "";
  const [golfImg, setGolfImg] = useState<string>("");
  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );
  const [startTimeFeilds, setStartTimeFeilds] = useState<IStartTime>({
    startTimeHour: "",
    startTimeMinute: "",
    startTimeFormat: "",
    isShowRequiredError: false,
  });
  const [endTimeFeilds, setEndTimeFeilds] = useState<IEndTime>({
    endTimeHour: "",
    endTimeMinute: "",
    endTimeFormat: "",
    isShowRequiredError: false,
  });
  const [isDomainAvailable, setIsDomainAvailable] = useState<boolean>(true);
  const [isCheckingDomain, setIsCheckingDomain] = useState<boolean>(false);
  const [logoFileName, setLogoFileName] = useState<string>(
    editData?.marketingWebLogo || ""
  );
  const [websiteFileName, setWebsiteFileName] = useState<string>(
    editData?.marketingWebImage || ""
  );
  const [isDomainChecked, setIsDomainChecked] = useState<boolean>(false);
  const [originalDomain, setOriginalDomain] = useState<string>(editData?.domain || "");
  const [selectedCountry, setSelectedCountry] = useState<any>()
  const [selectedState, setSelectedState] = useState<any>()
  const [selectedCity, setSelectedCity] = useState<any>()
  const initialValueForSchedule = [
    {
        "day": "Monday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Tuesday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Wednesday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Thursday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Friday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Saturday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Sunday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    }
]
    const [schedule, setSchedule] = useState<IDailySchedule[]>(initialValueForSchedule);
    const initialValueForDayCount = [
      {
        day: "Monday",
        counts: '' 
      },
      {
        day: "Tuesday",
       counts: ''
      },
      {
        day: "Wednesday",
       counts:'' 
      },
      {
        day: "Thursday",
       counts:'' 
      },
      {
        day: "Friday",
       counts:''
      },
      {
        day: "Saturday",
       counts:''
      },
      {
        day: "Sunday",
       counts:''
      }
  ]
    const [dayCount, setDayCount] = useState<any>(initialValueForDayCount)

  
  
  const [selectedDay, setSelectedDay] = useState<string>("Monday");
  const [isChecked, setIsChecked] = useState(false)
  // const [isEndTimeBefore, setEndTimeBefore] = useState(false);

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    type: Yup.string().required("Hole type selection is required"),
    description: Yup.string().required("Description is required"),
    privacyDescription: Yup.string().required(
      "Privacy Description is required"
    ),
    minThreshold: Yup.number().required("ThreshHold amount is required").min(1, "Minimum Threshold Amount should be greater than zero"),
    minSqueezAmount: Yup.number().required('Auto approve amount is required').min(1, "Auto - Approved Amount should be greater than zero")
        .test('autoapprove-amount', 'Auto approve amount should be greater than minimum thresold amount', function (value) {
          if (!value) return false;
          const minThresold = formik.values.minThreshold;
    
          if(value <= minThresold){
            return false;
          }
    
          return true
        }),
    rulesDescription: Yup.string().required("Rules Description is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    addressName: Yup.string().trim().required("Address is required"),
    street: Yup.string().trim().required("Street is required"),
    personCount: Yup.number().required("Person count is required").min(1, "Person count should be greater than zero"),
    maximumPrice: Yup.number().required("Maximum price is required").min(1, "Maximum price should be greater than zero")
      .test('maximum-price', 'Maximum price should be greater than or equal to auto approve amount', function (value) {
        if (!value) return false;
        const autoApprovePrice = formik.values.minSqueezAmount;
        const isAutoApprove = formik.values.IsAutoApprove;

        if (value < autoApprovePrice && isAutoApprove) {
          return false;
        }

        return true
      }),
    // unit: Yup.string().trim().required("Unit is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    country: Yup.string().trim().required("Country is required"),
    zip: Yup.string().trim().required("Zip is required"),
    golfType: Yup.string().required("Golf type selection is required"),
    rentalGolfPrice: Yup.number().required("Rental golf price is required"),
    websiteURL: Yup.string().trim().required("Booking URL is required"),
    contactNumber: Yup.string()
          .required("Please enter mobile number")
          .test('mobile-number', 'Mobile number format is incorrect', function (value) {
            if (!value) return false;
            const cleanedNumber = value.replace(formik.values.countryCode, '').replace(/\D/g, '');
            if (cleanedNumber.length === 0) {
              return this.createError({ message: "Please enter mobile number" });
            }
            if (cleanedNumber.length < 10) {
              return false;
            }
            return true;
          }),
    teeTimeDescription: Yup.string().required(
      "Tee Time Description is required"
    ),
    teeTimePolicy: Yup.string().required("Tee Time Policy is required"),
    isSqueezOpen: Yup.boolean()
    .test('at-least-one', 'At least one of Squeez or Waitlist must be selected', function (value) {
      const { isWaitListOpen } = this.parent;
      if (value || isWaitListOpen) {
        return true;
      }
      return false;
    }),
    isWaitListOpen: Yup.boolean()
      .test('at-least-one', 'At least one of Squeez or Waitlist must be selected', function (value) {
        const { isSqueezOpen } = this.parent;
        if (value || isSqueezOpen) {
          return true;
        }
        return false;
      })
    // golfImage: Yup.mixed().required("Golf Image is required"),
  });
  // const hours = [...Array(12)].map((_, index) =>
  //   index < 9 ? 0 + `${index + 1}` : `${index + 1}`
  // );
  // const minutes = [...Array(60)].map((_, index) =>
  //   index <= 9 ? 0 + `${index}` : `${index}`
  // );

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    error: countryAPIError,
  } = useQuery("countries", getCountries);
  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
    error: stateAPIError,
  } = useMutation("states", getStates);
  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation("cities", getCities);
  const {
    mutateAsync: addGolf,
    isLoading: isAddingGolf,
    error: addGoldAPIError,
  } = useMutation("add-golf", addGolfClub);
  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    refetch: refetchCategories,
  } = useQuery("category", getCategories);
  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture
  );

  const restaurant = categoryData?.data?.docs?.find(
    (s: any) => s?._id == process.env.REACT_APP_ID_GOLF_CATE
  );

  const formik: any = useFormik({
    initialValues: {
      name: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      type: editData?.type ?? "",
      description: editData?.description ?? "",
      latitude: editData?.Latitude ?? "",
      longitude: editData?.Longitude ?? "",
      addressName: editData?.address?.name
        ? editData?.address?.name?.replace(/^\s+|\s+$/g, "")
        : "",
      street: editData?.address?.street
        ? editData?.address?.street?.replace(/^\s+|\s+$/g, "")
        : "",
      unit: editData?.address?.unit ?? "",
      minThreshold: editData?.minThreshold ?? null,
      city: editData?.address?.cityId ?? "",
      zip: editData?.address?.zip ?? "",
      primaryColor: editData?.primaryColor,
      secondaryColor: editData?.secondaryColor,
      lightColor: editData?.lightColor,
      state: editData?.address?.stateId ?? "",
      country: editData?.address?.countryId ?? "",
      personCount: editData?.maxPersonCount || null,
      maximumPrice: editData?.priceRange?.maxPrice || null,
      domain: editData?.domain,
      golfType: editData?.golfType ?? "",
      isSqueezOpen: editData?.featureSqueezWaitlist?.isSqueezOn ?? true,
      isWaitListOpen: editData?.featureSqueezWaitlist?.isWaitlistOn ?? true,
      privacyDescription: editData?.privacyDescription ?? "",
      rulesDescription: editData?.rulesDescription ?? "",
      minAmount: editData?.min ?? "",
      maxAmount: editData?.max ?? "",
      rentalGolfPrice: editData?.rentalGolfPrice ?? 0,
      IsParkingOpen: editData?.IsParkingOpen ?? false,
      tax: editData?.tax ?? 0,
      convenienceFee: editData?.convenienceFee ?? 0,
      websiteURL: editData?.websiteURL
        ? editData?.websiteURL?.replace(/^\s+|\s+$/g, "")
        : "",
      contactNumber: editData?.contactNumber ?? "",
      countryCode: editData?.countryCode ?? "",
      teeTimeDescription: editData?.teeTimeDescription ?? "",
      teeTimePolicy: editData?.teeTimePolicy ?? "",
      minSqueezAmount: editData?.minSqueezAmount ?? null,
      IsAutoApprove: editData?.IsAutoApprove ?? true,
      cateId: restaurant?._id,
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {

      const formattedMaximumPrice = {
        maxPrice: values?.maximumPrice
      }

      const isHoursOfOperationValid = schedule?.every((dayData) => {
        const isHoursOfOperationOneValid = (dayData?.hoursOfOperationOne?.name && dayData?.hoursOfOperationOne?.startTime && dayData?.hoursOfOperationOne?.endTime) ||
                                            (!dayData?.hoursOfOperationOne?.name && !dayData?.hoursOfOperationOne?.startTime && !dayData?.hoursOfOperationOne?.endTime);
        
        const isBusiestDayOneValid = (dayData?.busiestDayOne?.startTime && dayData?.busiestDayOne?.endTime) ||
                                     (!dayData?.busiestDayOne?.startTime && !dayData?.busiestDayOne?.endTime);
  
        let isValid;

        isValid = dayData?.IsOpen ? (isHoursOfOperationOneValid && isBusiestDayOneValid) : true
      
        return isValid;
      });

      const formattedBookingPreferences = {
        isSqueezOn: values?.isSqueezOpen,
        isWaitlistOn: values?.isWaitListOpen
      }

      const formattedDayCountValue = dayCount?.map((time: any) => {
        return {
          ...time,
          counts: time?.counts !== '' ? Number(time?.counts) : ''
        };
      });

      const formattedSchedule = schedule?.map((time: any) => {
        const isOpen = 
          !time?.hoursOfOperationOne?.startTime &&
          !time?.hoursOfOperationOne?.endTime &&
          !time?.busiestDayOne?.startTime &&
          !time?.busiestDayOne?.endTime
      
        return {
          ...time,
          IsOpen: isOpen ? false : time?.IsOpen,
      
          hoursOfOperationOne: {
            ...time?.hoursOfOperationOne,
            startTime: time?.hoursOfOperationOne?.startTime ? convertDayjsFormatTimeTo24HourFormat(time?.hoursOfOperationOne?.startTime?.$d) : '',
            endTime: time?.hoursOfOperationOne?.endTime ? convertDayjsFormatTimeTo24HourFormat(time?.hoursOfOperationOne?.endTime?.$d) : ''
          },
          busiestDayOne: {
            ...time?.busiestDayOne,
            startTime: time?.busiestDayOne?.startTime ? convertDayjsFormatTimeTo24HourFormat(time?.busiestDayOne?.startTime?.$d) : '',
            endTime: time?.busiestDayOne?.endTime ? convertDayjsFormatTimeTo24HourFormat(time?.busiestDayOne?.endTime?.$d) : ''
          },
        };
      });

      if (values?.domain?.trim() === "" || values?.domain?.trim() === undefined) {
        formik.setFieldError("domain", "Please enter domain name");
        return;
        }
        if (values.domain.trim() !== "" && values.domain !== originalDomain) {
          setIsCheckingDomain(true);
          const isAvailable = await checkDomainAvailability(values.domain, restaurant?._id);
          setIsCheckingDomain(false);
          setIsDomainAvailable(isAvailable);
          setIsDomainChecked(true);
      
          if (!isAvailable) {
            formik.setFieldError("domain", "Domain already exists");
            return; 
          }
        } else if (!isDomainAvailable && values.domain.trim() !== "") {
          formik.setFieldError("domain", "Domain already exists");
          return; 
        }
      
        if (isDomainAvailable && values.domain.trim() !== "") {
          formik.setFieldError("domain", "");
        }

      const country = countryData?.data?.find(
        (c: any) => c?.countryId == values?.country
      );
      const state = stateData?.data?.find(
        (s: any) => s?.stateId == values?.state
      );
      const city = cityData?.data?.find((c: any) => c?.cityId == values?.city);
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        cateId: restaurant?._id,
        name: values?.name ? values?.name?.replace(/^\s+|\s+$/g, "") : "",
        type: values?.type ?? "",
        description: values?.description ?? "",
        address: {
          name: values?.addressName
            ? values?.addressName?.replace(/^\s+|\s+$/g, "")
            : "",
          street: values?.street
            ? values?.street?.replace(/^\s+|\s+$/g, "")
            : "",
          unit: "",
          cityId: values?.city ?? "",
          zip: values?.zip ?? "",
          stateId: values?.state ?? "",
          countryId: values?.country ?? "",
          country: country?.name ?? "",
          state: state?.name ?? "",
          city: city?.name ?? "",
        },
        Latitude: values?.latitude ?? "",
        Longitude: values?.longitude ?? "",
        golfType: values?.golfType ?? "",
        IsParkingOpen: values?.IsParkingOpen,
        privacyDescription: values?.privacyDescription ?? "",
        rulesDescription: values?.rulesDescription ?? "",
        min: values?.minAmount ?? "",
        primaryColor: values?.primaryColor, 
        secondaryColor: values?.secondaryColor,
        lightColor: values?.lightColor,
        marketingWebLogo: logoFileName,
        maxPersonCount: values?.personCount,
        priceRange: formattedMaximumPrice,
        marketingWebImage: websiteFileName,
        domain: values?.domain,
        max: values?.maxAmount ?? "",
        rentalGolfPrice: values?.rentalGolfPrice ?? 0,
        tax: values?.tax ?? 0,
        requestCounts: formattedDayCountValue,
        featureSqueezWaitlist: formattedBookingPreferences,
        minThreshold:values?.minThreshold ?? 0,
        hoursOfOperationList: formattedSchedule,
        convenienceFee: values?.convenienceFee ?? 0,
        websiteURL: values?.websiteURL
          ? values?.websiteURL?.replace(/^\s+|\s+$/g, "")
          : "",
        contactNumber: values?.contactNumber
          ? values?.contactNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        countryCode: values?.countryCode ?? "1",
        picture: golfImg ? [golfImg] : "",
        teeTimeDescription: values?.teeTimeDescription ?? "",
        teeTimePolicy: values?.teeTimePolicy ?? "",
        minSqueezAmount: values?.minSqueezAmount ?? 0,
        IsAutoApprove: values?.IsAutoApprove ?? true,
      };  
      if(isHoursOfOperationValid){
      let response = await addGolf(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Golf Added Successfully!");
        formik.resetForm();
        setImgFileName("");
        setGolfImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    }
    },
  });
  const countryValue = formik.values.country;
  const stateValue = formik.values.state;

  useEffect(() => {
    if(editData?.requestCounts?.length){
      const formattedDayCount = editData?.requestCounts?.map((time: any) => {
        return{
          ...time,
          counts: time?.counts != null ? String(time?.counts) : ''
        }
      })
      setDayCount(formattedDayCount)
    }
    else{
      setDayCount(initialValueForDayCount)
    }
    if (editData) {
      if (editData?.hoursOfOperationList?.length) {
        const formattedHoursOfOperation = editData?.hoursOfOperationList?.map((time: any) => {
          return {
            ...time,
      
            hoursOfOperationOne: {
              ...time?.hoursOfOperationOne,
              startTime: time?.hoursOfOperationOne?.startTime ? convertToDayjsFormat(time?.hoursOfOperationOne?.startTime) : '',
              endTime: time?.hoursOfOperationOne?.endTime ? convertToDayjsFormat(time?.hoursOfOperationOne?.endTime) : ''
            },
    
            busiestDayOne: {
              ...time?.busiestDayOne,
              startTime: time?.busiestDayOne?.startTime ? convertToDayjsFormat(time?.busiestDayOne?.startTime) : '',
              endTime: time?.busiestDayOne?.endTime ? convertToDayjsFormat(time?.busiestDayOne?.endTime) : ''
            },
          };
        });
      
        setSchedule(formattedHoursOfOperation);
      } else {
        setSchedule(initialValueForSchedule);
      }
      
      formik.setFieldValue(
        "contactNumber",
        editData?.countryCode + editData?.contactNumber
      );
      if (imgUrl) {
        setGolfImg(imgUrl);
      }
    }
  }, [editData]);

  useEffect(() => {
    if (countryValue) {
      getStateData({ countryId: countryValue });
    }
    formik.setFieldValue('state', null);
    setSelectedState(null);
  }, [countryValue]);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue });
    }
    else{
      formik.setFieldValue('city', null);
      setSelectedCity(null)
    }
  }, [stateValue]);


  const checkDomainAvailability = async (domain: any, cateId: any) => {
    try {
      const response = await httpsClient.post(APP_URLs.check_domain.domain_avaliable, {
        domain,
        cateId
      });
      return response.data.data;
    } catch (error) {
      console.error("Error checking domain availability", error);
      return false;
    }
  };

  // const handleTimeSelection = (
  //   value: string | number,
  //   fieldName: string,
  //   type: string,
  // ) => {
  //   if (type === "START_TIME") {
  //     setStartTimeFeilds((prevState: IStartTime) => {
  //       return {
  //         ...prevState,
  //         [fieldName]: value,
  //         isShowRequiredError: false,
  //       };
  //     });
  //   } else {
  //     setEndTimeFeilds((prevState: IEndTime) => {
  //       return {
  //         ...prevState,
  //         [fieldName]: value,
  //         isShowRequiredError: false,
  //       };
  //     });
  //   }
  //   setEndTimeBefore(false);
  // };

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setGolfImg(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  const handleRemoveImage = (type: any) => {
    if(type == "websitelogo" || type == "Golf Image"){
      setImgFileName("")
      setLogoFileName("")
      setGolfImg("")
    }
    else if(type == "websiteimage"){
      setWebsiteFileName("")
      setGolfImg("")
    }
  }

  const handleChangeImageUploadImage = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setWebsiteFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setWebsiteFileName(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  const updateSchedule = (day: any, type: any, value: any) => {
      setSchedule((prev: any) =>
        prev.map((item: any) =>
          item?.day === day
            ? {
                ...item,
                ...(type.startsWith('hoursOfOperationOne') && {
                  hoursOfOperationOne: {
                    ...item?.hoursOfOperationOne,
                    [type.split('.')[1]]: value,
                    ...(type.endsWith('startTime') && { endTime: '' }),
                  },
                }),
                ...(type.startsWith('busiestDayOne') && {
                  busiestDayOne: {
                    ...item?.busiestDayOne,
                    [type.split('.')[1]]: value,
                    ...(type.endsWith('startTime') && { endTime: '' }),
                  },
                }),
                ...(type === 'IsOpen' && { [type]: value }),
              }
            : item
        )
      );
  // }
  }

  useEffect(() => {
    if(editData && countryData && stateData && cityData && !selectedState && !selectedCity && !selectedCountry){
      const filteredStateValue = stateData?.data?.filter((Id: any) => Id?.stateId === editData?.address?.stateId)
      const stateValue = {
        label: filteredStateValue[0]?.name,
        value: filteredStateValue[0]?.stateId
      }
      formik.setFieldValue('state', stateValue?.value);
      setSelectedState(stateValue)

      const filteredCityValue = cityData?.data?.filter((Id: any) => Id?.cityId === editData?.address?.cityId)
      const cityValue = {
        label: filteredCityValue[0]?.name,
        value: filteredCityValue[0]?.cityId
      }
      formik.setFieldValue('city', cityValue?.value); 
      setSelectedCity(cityValue)

      const filteredCountryValue = countryData?.data?.filter((Id: any) => Id?.countryId === editData?.address?.countryId)
      const countryValue = {
        label: filteredCountryValue[0]?.name,
        value: filteredCountryValue[0]?.countryId
      }
      formik.setFieldValue('country', countryValue?.value);
      setSelectedCountry(countryValue)
    }
  },[countryData, stateData, cityData, editData])

  const countryOptions = countryData?.data?.map((country: any) => ({
    value: country?.countryId,
    label: `${country?.name} (${country?.code})`,
  }));

  const stateOptions = stateData?.data?.map((state: any) => ({
    value: state?.stateId,
    label: `${state?.name}`,
  }));

  const cityOptions = cityData?.data?.map((city: any) => ({
    value: city?.cityId,
    label: `${city?.name}`,
  }));

  
  const handleChipClick = (day: string) => {
    setSelectedDay(day)
  };
  
  const handleSameCountClick = (e: any, counts: any) => {
    const isChecked = e?.target?.checked;
    setIsChecked(isChecked)
    setDayCount((prev: any) => prev.map((item: any) => ({ ...item, ['counts']: counts })));
  }

  const handleCountChange = (type: any , value: any) => {
    if(value >= 0){
    setDayCount((prev: any) => 
      prev.map((item: any) =>
        item?.day === selectedDay ? { ...item, [type]: value } : item
      )
    );
    setIsChecked(false)
  }
  }

  const handleChangeImageUploadLogo = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setLogoFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setLogoFileName(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => {
        setImgFileName("");
        setGolfImg("");
        handleClose(false);
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h2> {editData?._id ? "Edit" : "Add"} Golf</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            setImgFileName("");
            setGolfImg("");
            handleClose(false);
          }}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6" style={{ height: "80vh", overflowY: "auto" }}>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <div>
                  <label className="form-label fw-bold">Hole Type{''} <span className="required-icon">*</span></label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("type")}
                  >
                    <option value="">Select Type</option>
                    <option value="9Holes">9 Holes</option>
                    <option value="18Holes">18 Holes</option>
                  </select>
                  {formik.touched.type && formik.errors.type && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.type}</div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Golf Type{''} <span className="required-icon">*</span></label>
                <select
                  {...formik.getFieldProps("golfType")}
                  className="form-select form-select-solid form-select-lg"
                >
                  <option value="">Select All</option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                  <option value="SemiPrivate">Semi Private</option>
                  <option value="Resort">Resort</option>
                </select>
                {formik.touched.golfType && formik.errors.golfType && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.golfType}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Image upload</label>
                <div>
                  {imgFileName != "" ? (
                   <div className="add-golf-image-upload">
                   <p style={{ display: "inline-block", marginRight: "30px", marginTop:"12px", paddingLeft:"13px" }}>{charValidate(imgFileName, 35)}</p>
                   <CancelIcon
                     onClick={() => handleRemoveImage("Golf Image")}
                     className="file-remove-icon"
                   />
                 </div>
                  ):(
                    <div style={{ maxHeight:'65px' }}>
                    <label
                    htmlFor="golf-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                    >
                    {imgFileName
                    ? charValidate(imgFileName, 25)
                    : "Choose File"}
                    </label>
                    <input
                    id="golf-img"
                    type="file"
                    accept="image/*"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Upload Image"
                    aria-label="Upload Image"
                    onChange={handleChangeImageUpload}
                    style={{
                      visibility: "hidden",
                    }}
                  />
                  </div>
                  )}
                </div>
              </Col>
            </Row>

            
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Latitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Latitude"
                  aria-label="Latitude"
                  {...formik.getFieldProps("latitude")}
                />
                {formik.touched.latitude && formik.errors.latitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.latitude}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6}>
                <label className="form-label fw-bold">Longitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Longitude"
                  aria-label="Longitude"
                  {...formik.getFieldProps("longitude")}
                />
                {formik.touched.longitude && formik.errors.longitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.longitude}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
             
            <Col xs={6}>
                <label className="form-label fw-bold">Booking Preferences{''} <span className="required-icon">*</span></label>
                <div className="fv-row d-flex">
                  <label
                    className="form-check form-check-inline"
                    style={{ paddingTop: 7 }}
                  >
                    Squeez
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) =>  formik.setFieldValue('isSqueezOpen', e.target.checked)}
                      checked={formik.values.isSqueezOpen}
                    />
                  </label>

                  <label
                    className="form-check form-check-inline"
                    style={{ paddingTop: 7 }}
                  >
                    Waitlist
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) => formik.setFieldValue('isWaitListOpen', e.target.checked)}
                      checked={formik.values.isWaitListOpen}
                    />
                  </label>
                </div>
                {formik.touched.isWaitListOpen && formik.errors.isWaitListOpen && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.isWaitListOpen}
                        </div>
                      </div>
                    )}
              </Col>
            </Row>

            
            <Row className="highlight-section mt-4">
            <Col xs={12}>
            <label className="col-lg-4 col-form-label fw-bold fs-6">
              <span>Address</span>
            </label>
            </Col> 
            <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Address Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("addressName")}
                />
                {formik.touched.addressName && formik.errors.addressName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.addressName}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Street{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Street"
                  aria-label="Street"
                  {...formik.getFieldProps("street")}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.street}
                    </div>
                  </div>
                )}
              </Col>

              {/* <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Unit{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Unit"
                  aria-label="Unit"
                  {...formik.getFieldProps("unit")}
                />
                {formik.touched.unit && formik.errors.unit && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.unit}
                    </div>
                  </div>
                )}
              </Col> */}

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Country{''} <span className="required-icon">*</span></label>
<Select
                  isDisabled={isLoadingCountry}
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('country', selectedOption?.value);
                    setSelectedCountry(selectedOption);
                  }}
                  placeholder="Select Country"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.country}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">State{''} <span className="required-icon">*</span></label>
<Select
                  isDisabled={isLoadingState}
                  options={stateOptions}  // Pass the options for react-select (state data)
                  value={selectedState}  // Set the selected value
                  onChange={(selectedOption: any) => 
                    {
                     formik.setFieldValue('state', selectedOption?.value);
                     setSelectedState(selectedOption);
                    }}
                  placeholder="Select State"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.state}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">City{''} <span className="required-icon">*</span></label>
<Select
                  isDisabled={isLoadingCity}
                  options={cityOptions}
                  value={selectedCity}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('city', selectedOption?.value);
                    setSelectedCity(selectedOption);
                  }}
                  placeholder="Select City"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.city}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Zip{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Zip"
                  aria-label="Zip"
                  {...formik.getFieldProps("zip")}
                />
                {formik.touched.zip && formik.errors.zip && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.zip}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={12}>
                <label className="form-label fw-bold">Description{''} <span className="required-icon">*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.description}
                  onChange={(content) =>
                    formik.setFieldValue("description", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}

            <Row
              className={`${
                formik.touched.description && formik.errors.description
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Golf Privacy Description{''} <span className="required-icon">*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.privacyDescription}
                  onChange={(content) =>
                    formik.setFieldValue("privacyDescription", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.privacyDescription &&
              formik.errors.privacyDescription && (
                <div className="fv-plugins-message-container mt-14">
                  <div className="fv-help-block">
                    {formik.errors.privacyDescription}
                  </div>
                </div>
              )}

            <Row
              className={`${
                formik.touched.privacyDescription &&
                formik.errors.privacyDescription
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Golf Rules Description{''} <span className="required-icon">*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.rulesDescription}
                  onChange={(content) =>
                    formik.setFieldValue("rulesDescription", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.rulesDescription && formik.errors.rulesDescription && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">
                  {formik.errors.rulesDescription}
                </div>
              </div>
            )}

            <Row
              className={`${
                formik.touched.rulesDescription &&
                formik.errors.rulesDescription
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Tee Time Description{''} <span className="required-icon">*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.teeTimeDescription}
                  onChange={(content) =>
                    formik.setFieldValue("teeTimeDescription", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.teeTimeDescription &&
              formik.errors.teeTimeDescription && (
                <div className="fv-plugins-message-container mt-14">
                  <div className="fv-help-block">
                    {formik.errors.teeTimeDescription}
                  </div>
                </div>
              )}

            <Row
              className={`${
                formik.touched.teeTimeDescription &&
                formik.errors.teeTimeDescription
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">Tee Time Policy{''} <span className="required-icon">*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.teeTimePolicy}
                  onChange={(content) =>
                    formik.setFieldValue("teeTimePolicy", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.teeTimePolicy && formik.errors.teeTimePolicy && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">
                  {formik.errors.teeTimePolicy}
                </div>
              </div>
            )}

            <Row 
              className={`${
                formik.touched.rulesDescription &&
                formik.errors.rulesDescription
                  ? "pt-6"
                  : "pt-17"
              }`}>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">
                  Rental Golf Price (Amount)
                </label>
                <AmountInput
                  placeholder="Rental Golf Price"
                  formikProps={{ ...formik.getFieldProps("rentalGolfPrice") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {formik.touched.rentalGolfPrice &&
                  formik.errors.rentalGolfPrice && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.rentalGolfPrice}
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Tax (%)</label>
                <AmountInput
                  placeholder="Tax"
                  formikProps={{ ...formik.getFieldProps("tax") }}
                  handleChange={(e) => formik.handleChange(e)}
                  isPercentage={true}
                />
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">
                  Convenience Fee (Amount)
                </label>
                <AmountInput
                  placeholder="Convenience Fee"
                  formikProps={{ ...formik.getFieldProps("convenienceFee") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
              </Col>
            </Row>
            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Booking URL{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Booking URL"
                  aria-label="Booking URL"
                  {...formik.getFieldProps("websiteURL")}
                />
                {formik.touched.websiteURL && formik.errors.websiteURL && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.websiteURL}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Mobile Number{''} <span className="required-icon">*</span></label>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  masks={{
                    us: "...-...-....",
                    in: "...-...-....",
                  }}
                  // variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.contactNumber}
                  onChange={(value: string, country: any) => {
                    formik.setFieldValue(
                      "countryCode",
                      country?.dialCode || ""
                    );
                    formik.setFieldValue("contactNumber", value || "");
                  }}
                  inputStyle={{
                    background: "#f9f9f9",
                    border: "none",
                  }}
                  inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  dropdownStyle={{
                    maxHeight: "200px",
                  }}
                  // disableDropdown={true}
                  // disableCountryCode={true}
                  // onlyCountries={["us"]}
                />
                {formik.touched.contactNumber && formik.errors.contactNumber && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.contactNumber}
                    </div>
                  </div>
                )}
              </Col>
              </Row>
           
              <Row className="pt-6">
                <Col xs={6}>
                  <label className="form-label fw-bold">
                  Minimum Threshold Amount <span className="required-icon">*</span>
                  </label>
                  <AmountInput
                    placeholder="Minimum Threshold Amount"
                    formikProps={{ ...formik.getFieldProps("minThreshold") }}
                    handleChange={(e) => formik.handleChange(e)}
                  />
                  {formik.touched.minThreshold && formik.errors.minThreshold && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.minThreshold}
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={6}>
                  <label className="form-label fw-bold">
                  Auto - Approved Amount (per person) <span className="required-icon">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Auto - Approved Amount (per person)"
                    {...formik.getFieldProps("minSqueezAmount")}
                  />
                  {formik.touched.minSqueezAmount &&
                    formik.errors.minSqueezAmount && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.minSqueezAmount}
                        </div>
                      </div>
                    )}
                </Col>
              </Row>
            
              <Row>

              <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Auto Approve</label>
                  
                  <Form.Check
                    type="switch"
                    checked={formik.values.IsAutoApprove}
                    id="custom-switch"
                    {...formik.getFieldProps("IsAutoApprove")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>

                
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Valet Parking</label>
                  <Form.Check
                    type="switch"
                    checked={formik.values.IsParkingOpen}
                    id="custom-switch"
                    {...formik.getFieldProps("IsParkingOpen")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row>
              <Col xs={6} className="pt-6">
              <label className="form-label fw-bold">Maximum Person{''} <span className="required-icon">*</span></label>
              <AmountInput
                  placeholder="Please enter the person count"
                  formikProps={{ ...formik.getFieldProps("personCount") }}
                  handleChange={(e) => formik.handleChange(e)}
                  isShowIcon={false}
                />
                {formik.touched.personCount &&
                    formik.errors.personCount && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.personCount}
                        </div>
                      </div>
                    )}
              </Col>

              <Col xs={6} className="pt-6">
              <label className="form-label fw-bold">Maximum Price{''} <span className="required-icon">*</span></label>
                <AmountInput
                  placeholder="Please enter the maximum price"
                  formikProps={{ ...formik.getFieldProps("maximumPrice") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {formik.touched.maximumPrice &&
                    formik.errors.maximumPrice && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.maximumPrice}
                        </div>
                      </div>
                    )}
              </Col>
              </Row>

            <Row className="highlight-section mt-6">
                <Col xs={12} className="pt-6">
                  <label className="form-label fw-bold">
                    Allocation
                  </label>
                  <Stack direction="row" spacing={1}>
                    {dayCount?.map((dayData: any) => (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Chip
                          key={dayData?.day}
                          label={dayData?.day}
                          variant={selectedDay === dayData.day ? "filled" : "outlined"}
                          color={selectedDay === dayData.day ? "primary" : "default"}
                          onClick={() => handleChipClick(dayData.day)}
                          className="mb-2"
                        />
                      </div>
                    ))}
                  </Stack>
                </Col>
                <Col xs={8}>
                  {dayCount?.map((dayData: any) => (
                    <>
                    <div style={{ display: "flex", flexDirection: "column", width:'94%' }}>
                      {dayData.day === selectedDay && (
                        <input
                          type="number"
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          placeholder={`Please enter the allocation for ${selectedDay}`}
                          min="0"
                          value={dayData?.counts}
                          onChange={(e: any) => handleCountChange('counts', e.target.value)}
                        />
                      )}
                    </div>
                    {dayData.day === selectedDay && (
                      <div className="mt-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="count-checkbox"
                      checked={isChecked}
                      onChange={(e) => handleSameCountClick(e, dayData?.counts)}
                    />{" "}
                <label className="fw-bold mt-1" style={{ fontSize:'12px' }}>Select the checkbox to pass the same allocation for all days</label>
                </div> 
                    )}
                </>
              ))}
         
                </Col>
              <Col xs={12}>
                <div className="card p-3 mb-4 shadow-sm border-light mt-5 w-100">
                  <div className="d-flex align-items-start">
                    <i className="bi bi-info-circle-fill fs-4 text-info me-3"></i>
                    <div style={{ maxHeight: '20px' }}>
                      <h5 className="card-title">
                        An empty field should be considered as an unlimited Squeez Request.
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Hours Of Operation
                </label>

                {schedule?.map((day: any) => (
                  <Accordion key={day?.day} expanded={day?.IsOpen} onChange={() => updateSchedule(day?.day, 'IsOpen', !day?.IsOpen)}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`${day?.day}-content`}
                      id={`${day?.day}-header`}
                      onClick={() => updateSchedule(day?.day, 'IsOpen', !day?.IsOpen)}
                    >
                      <Typography className="text-capitalize">{day?.day}</Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={day?.IsOpen}
                            onChange={(e) => updateSchedule(day?.day, 'IsOpen', e.target.checked)}
                            color="primary"
                          />
                        }
                        label={
                          <span style={{ minWidth: 40, display: 'inline-block' }}>
                            {day?.IsOpen ? "Open" : "Closed"}
                          </span>
                        }
                        onClick={(e) => e.stopPropagation()}
                        onFocus={(e) => e.stopPropagation()}
                        className="ms-3"
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="row">
                      <div className="d-flex flex-row">
                          {/* <Typography variant="subtitle1" gutterBottom>Lunch Hours</Typography> */}
                          <div className="w-100">
                          <input
                            placeholder="Please enter the title"
                            onChange={(e: any) => updateSchedule(day?.day, 'hoursOfOperationOne.name', e?.target?.value)}
                            value={day?.hoursOfOperationOne?.name}
                            disabled={!day?.IsOpen}
                            style={{
                              backgroundColor: !day?.IsOpen ? "#e5e5e5" : ""
                            }}
                            className="p-4 input-border form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          />
                          {day?.IsOpen && !day?.hoursOfOperationOne?.name && (day?.hoursOfOperationOne?.startTime  || day?.hoursOfOperationOne?.endTime ) && (
                               <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                 Title is required
                               </label>
                             )}
                             </div>
                          <Typography className="mx-2 fw-bold mt-4">:</Typography>
                          <div className="w-100">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                ampm={false}
                                timeSteps={{ minutes: 1 }}
                                onChange={(newValue: any) => updateSchedule(day?.day, 'hoursOfOperationOne.startTime', newValue)}
                                value={day?.hoursOfOperationOne?.startTime}
                                disabled={!day?.IsOpen}
                                sx={{
                                  width: "100%",
                                  "& .MuiOutlinedInput-input": { py: 1.35 },
                                }}
                                slotProps={{
                                  layout: {
                                    sx: {
                                      [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                        display: "none"
                                      }
                                    }
                                  }
                                }}
                              />
                            </LocalizationProvider>
                            {day?.IsOpen && !day?.hoursOfOperationOne?.startTime && (day?.hoursOfOperationOne?.name || day?.hoursOfOperationOne?.endTime) && (
                                 <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                   Start time is required
                                 </label>
                               )}
                               </div>
                            <Typography className="mx-2 mt-4">-</Typography>
                            <div className="w-100">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                ampm={false}
                                timeSteps={{ minutes: 1 }}
                                onChange={(newValue: any) => updateSchedule(day?.day, 'hoursOfOperationOne.endTime', newValue)}
                                value={day?.hoursOfOperationOne?.endTime}
                                disabled={!day?.IsOpen || !day?.hoursOfOperationOne?.startTime}
                                minTime={day?.hoursOfOperationOne?.startTime ? day?.hoursOfOperationOne?.startTime : undefined}
                                sx={{
                                  width: "100%",
                                  "& .MuiOutlinedInput-input": { py: 1.35 },
                                }}
                                slotProps={{
                                  layout: {
                                    sx: {
                                      [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                        display: "none"
                                      }
                                    }
                                  }
                                }}
                              />
                            </LocalizationProvider>
                            {day?.IsOpen && !day?.hoursOfOperationOne?.endTime && (day?.hoursOfOperationOne?.name || day?.hoursOfOperationOne?.startTime) && (
                                 <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                   End time is required
                                 </label>
                               )}
                               </div>
                          </div>
                      </Box>
                      <Box className="row mt-4">
                        <div className="col-md-6 mb-3">
                        <div className="d-flex align-items-center justify-content-center"><Typography variant="subtitle1" className="fw-bold" gutterBottom>Busiest Time</Typography></div>
                          <div className="d-flex flex-row">
                            <div className="w-100">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  ampm={false}
                                  timeSteps={{ minutes: 1 }}
                                  onChange={(newValue: any) => updateSchedule(day?.day, 'busiestDayOne.startTime', newValue)}
                                  value={day?.busiestDayOne?.startTime}
                                  disabled={!day?.IsOpen}
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-input": { py: 1.35 },
                                  }}
                                  slotProps={{
                                    layout: {
                                      sx: {
                                        [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                          display: "none"
                                        }
                                      }
                                    }
                                  }}
                                />
                              </LocalizationProvider>
                              {day?.IsOpen && !day?.busiestDayOne?.startTime && day?.busiestDayOne?.endTime && (
                                <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                  Start time is required
                                </label>
                              )}
                            </div>
                            <Typography className="mx-2 mt-4">-</Typography>
                            <div className="w-100">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  ampm={false}
                                  timeSteps={{ minutes: 1 }}
                                  onChange={(newValue: any) => updateSchedule(day?.day, 'busiestDayOne.endTime', newValue)}
                                  value={day?.busiestDayOne?.endTime}
                                  disabled={!day?.IsOpen || !day?.busiestDayOne?.startTime}
                                  minTime={day?.busiestDayOne?.startTime ? day?.busiestDayOne?.startTime : undefined}
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-input": { py: 1.35 },
                                  }}
                                  slotProps={{
                                    layout: {
                                      sx: {
                                        [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                          display: "none"
                                        }
                                      }
                                    }
                                  }}
                                />
                              </LocalizationProvider>
                              {day?.IsOpen && !day?.busiestDayOne?.endTime && day?.busiestDayOne?.startTime && (
                                <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                  End time is required
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Col>
            </Row>

              <Row className="pt-6 highlight-section mt-4">
              <Col xs={6}>
              <label className="form-label fw-bold">Domain URL{''} <span className="required-icon">*</span></label>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Domain URL"
                      {...formik.getFieldProps("domain")}
                      style={{ flex: '1' }}
                    />
                    <span className="form-label fw-bold ">.trysqueez.com</span>
                  </div>
                  {formik.touched.domain && formik.errors.domain && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.domain}</div>
                    </div>
                  )}
                  {isCheckingDomain && <p>Checking domain availability...</p>}
                </Col>
                <Col xs={6}>
                  <label className="form-label fw-bold">Primary Color</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      style={{ width: "80px", height: "50px", padding: "0", border: "none" }}
                      value={formik.values.primaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("primaryColor", e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control ms-3"
                      placeholder="#323232"
                      value={formik.values.primaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("primaryColor", e.target.value);
                      }}
                    />
                  </div>
                  {formik.touched.primaryColor && formik.errors.primaryColor && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.primaryColor}
                      </div>
                    </div>
                  )}
                </Col>
              <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Secondary Color</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      style={{ width: "80px", height: "50px", padding: "0", border: "none" }}
                      value={formik.values.secondaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("secondaryColor", e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control ms-3"
                      placeholder="#323232"
                      value={formik.values.secondaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("secondaryColor", e.target.value);
                      }}
                    />
                  </div>
                  {formik.touched.secondaryColor && formik.errors.secondaryColor && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.secondaryColor}
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Light Color</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      style={{ width: "80px", height: "50px", padding: "0", border: "none" }}
                      value={formik.values.lightColor}
                      onChange={(e) => {
                        formik.setFieldValue("lightColor", e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control ms-3"
                      placeholder="#323232"
                      value={formik.values.lightColor}
                      onChange={(e) => {
                        formik.setFieldValue("lightColor", e.target.value);
                      }}
                    />
                  </div>
                  {formik.touched.lightColor && formik.errors.lightColor && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.lightColor}
                      </div>
                    </div>
                  )}
                </Col>
              <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Website Logo</label>
                  <div>
                    {logoFileName != "" ? (
                      <div style={{ position: "relative", display: "inline-block", backgroundColor: "#f9f9f9", width: "100%", borderRadius: "5px" }}>
                        <p style={{ display: "inline-block", marginRight: "30px", marginTop: "12px", paddingLeft: "13px" }}>{charValidate(logoFileName, 35)}</p>
                        <CancelIcon
                          onClick={() => handleRemoveImage("websitelogo")}
                          className="file-remove-icon"
                        />
                      </div>
                    ) : (
                      <>
                        <label
                          htmlFor="logo-img"
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                        >
                          {logoFileName
                            ? charValidate(logoFileName, 25)
                            : "Choose File"}
                        </label>


                        <input
                          id="logo-img"
                          type="file"
                          accept="image/*"
                          className="form-control form-control-lg form-control-solid mb-0 mb-lg-0"
                          placeholder="Upload Image"
                          aria-label="Upload Image"
                          onChange={handleChangeImageUploadLogo}
                          style={{
                            display: "none",
                          }}
                        />
                      </>
                    )}
                  </div>
                </Col>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Website Image</label>
                  <div>
                    {websiteFileName != "" ? (
                      <div style={{ position: "relative", display: "inline-block", backgroundColor: "#f9f9f9", width: "100%", borderRadius: "5px" }}>
                        <p style={{ display: "inline-block", marginRight: "30px", marginTop: "12px", paddingLeft: "13px" }}>{charValidate(websiteFileName, 35)}</p>
                        <CancelIcon
                          onClick={() => handleRemoveImage("websiteimage")}
                          className="file-remove-icon"
                        />
                      </div>
                    ) : (
                      <>
                        <label
                          htmlFor="website-img"
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                        >
                          {websiteFileName
                            ? charValidate(websiteFileName, 25)
                            : "Choose File"}
                        </label>
                        <input
                          id="website-img"
                          type="file"
                          accept="image/*"
                          className="form-control form-control-lg form-control-solid mb-0 mb-lg-0"
                          placeholder="Upload Image"
                          aria-label="Upload Image"
                          onChange={handleChangeImageUploadImage}
                          style={{
                            display: "none",
                          }}
                        />
                      </>
                    )}
                  </div>
                </Col>
              </Row>

          </Container>
          <div className="w-100 d-flex align-items-center justify-content-end mt-5">
            <Button
              onClick={() => {
                formik.resetForm();
                setImgFileName("");
                setGolfImg("");
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingGolf ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

AddEditGolf.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditGolf.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default AddEditGolf;
