import Chip from "@mui/material/Chip";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import { de } from "date-fns/locale";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import React, { useState } from "react";
// import './CustomerInfo.css'
import './OnBoardCustomer.css'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import { convertDateToMMDDYYYY } from "../../helpers/commonFunctions";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  details: any;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const OnBoardCustomerInfo = ({ show, handleClose, details }: Props) => {
  const [alignment, setAlignment] = React.useState('restaurant');
  const [displayGolfInfo, setDisplayGolfInfo] = useState(false);
  const [displayRestaurantInfo,  setDisplayRestaurantInfo] = useState(true);
  const restaurantDetails = details?.preferences?.find((restaurant: any) => restaurant?.lunchEarliestTime);
  const golfDetails = details?.preferences?.find((golf: any) => golf?.timeRange)

  const handleChange = (
    event: any,
    newAlignment: string,
  ) => {
    if(event?.target?.value == 'golf'){
      setDisplayGolfInfo(true)
      setDisplayRestaurantInfo(false)
    }
    else{
      setDisplayRestaurantInfo(true)
      setDisplayGolfInfo(false)
    }
    setAlignment(newAlignment);
  };
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}                               
      backdrop="static"
    >
      
      <div className="modal-header">
        <h2>Business Inquiry Information</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-3" style={{ overflowY: 'auto' }}>
  <Card style={{ width: "100%", boxShadow: "none", paddingTop: 0 }}>
    <Card.Body style={{ maxHeight: '75vh', overflowY: 'auto' }}>
      {/* Title */}
      <Row>
        {/* <h5 className="fs-4">Business Information</h5> */}

        {/* Business Name */}
        <Col xs={12} md={6} className="mb-3">
          <div className="user-preference-basic-info">
            <label style={{ marginRight: '5px' }}><strong>Business Name</strong></label>
            {details?.businessName ? (
              <span>{details?.businessName}</span>
            ) : (
              '-'
            )}
          </div>
        </Col>

        {/* Website */}
        <Col xs={12} md={6} className="mb-3">
          <div className="user-preference-basic-info">
            <label style={{ marginRight: '5px' }}><strong>Website</strong></label>
            {details?.website ? (
              <span><a href={details?.website} target="_blank" rel="noopener noreferrer" className="text-primary">{details?.website}</a></span>
            ) : (
              '-'
            )}
          </div>
        </Col>

        {/* Full Name */}
        <Col xs={12} md={6} className="mb-3">
          <div className="user-preference-basic-info">
            <label style={{ marginRight: '5px' }}><strong>Name</strong></label>
            {(details?.fullName && details?.lastName) ? (
              <span>{details?.fullName} {details?.lastName}</span> // Split fullName into first and last
            ) : (
              '-'
            )}
          </div>
        </Col>

        {/* Email */}
        <Col xs={12} md={6} className="mb-3">
          <div className="user-preference-basic-info">
            <label style={{ marginRight: '5px' }}><strong>Email</strong></label>
            {details?.email ? (
              <span><a href={`mailto:${details?.email}`} className="text-primary">{details?.email}</a></span>
            ) : (
              '-'
            )}
          </div>
        </Col>

        {/* Mobile Number */}
        <Col xs={12} md={6} className="mb-3">
          <div className="user-preference-basic-info">
            <label style={{ marginRight: '5px' }}><strong>Mobile Number</strong></label>
            {details?.countryCode && details?.mobileNumber ? (
              <span>{details?.countryCode} {details?.mobileNumber}</span>
            ) : (
              '-'
            )}
          </div>
        </Col>

        {/* Industry */}
        <Col xs={12} md={6} className="mb-3">
          <div className="user-preference-basic-info">
            <label style={{ marginRight: '5px' }}><strong>Sector</strong></label>
            {details?.industry?.length > 0 ? (
              <span>{details?.industry.join(', ')}</span> // Join industry array into string
            ) : (
              '-'
            )}
          </div>
        </Col>

        {/* Email Verified */}
        <Col xs={12} md={6} className="mb-3">
          <div className="verified-style">
            <label style={{ marginRight: '5px' }}><strong>Email</strong></label>
            {details?.IsEmailVerified ? (
              <CheckCircleIcon sx={{ fontSize: 20, color: "green" }} />
            ) : (
              <CancelIcon sx={{ fontSize: 20, color: "red" }} />
            )}
          </div>
        </Col>

        {/* SMS Verified */}
        <Col xs={12} md={6} className="mb-3">
          <div className="verified-style">
            <label style={{ marginRight: '5px' }}><strong>SMS</strong></label>
            {details?.IsSMSVerified ? (
              <CheckCircleIcon sx={{ fontSize: 20, color: "green" }} />
            ) : (
              <CancelIcon sx={{ fontSize: 20, color: "red" }} />
            )}
          </div>
        </Col>

        {/* Terms and Conditions Verified */}
        <Col xs={12} md={6} className="mb-3">
          <div className="verified-style">
            <label style={{ marginRight: '5px' }}><strong>Terms</strong></label>
            {details?.IsTermsAndConditionsVerified ? (
              <CheckCircleIcon sx={{ fontSize: 20, color: "green" }} />
            ) : (
              <CancelIcon sx={{ fontSize: 20, color: "red" }} />
            )}
          </div>
        </Col>

        {/* Created At */}
        <Col xs={12} md={6} className="mb-3">
          <div className="user-preference-basic-info">
            <label style={{ marginRight: '5px' }}><strong>Join Date</strong></label>
            {details?.createdAt ? (
              <span>{convertDateToMMDDYYYY(details?.createdAt)}</span> // Format date
            ) : (
              '-'
            )}
          </div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
</div>
    </Modal>,
    modalsRoot,
  );
};

export default OnBoardCustomerInfo;
