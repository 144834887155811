import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";
import { WithChildren } from "../_metronic/helpers";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { isAdmin, isSuperAdmin } from "../hooks/useUserInfo";
import ApparelDetails from "../pages/apparel/ApparelDetails";
import Coupon from "../pages/coupon/Coupon";
import Prefrence from "../pages/preference/Preference";
import PreferenceDetails from "../pages/preference_details/PreferenceDetails";
import SquezzMe from "../pages/squezz-me/SquezzMe";
import SubscriptionInfo from "../pages/subscription-info/SubscriptionInfo";
import Category from "../pages/category/Category";
import RestaurantList from "../pages/Restaurant/RestaurantList";
import WaitingList from "../pages/waiting/waitingList";
import Preference from "../pages/preference/Preference";
import GolfClub from "../pages/golf/golfClub";
import GolfDetails from "../pages/golf/GolfDetails";
import Health_care_list from "../pages/health-care";
import WellnessList from "../pages/wellness";
import WellnessDetails from "../pages/wellness/WellnessDetails";
import DashboardPage from "../pages/dashboard/DashboardPage";
import HotelList from "../pages/hotel/HotelList";
import HotelDetails from "../pages/hotel/HotelDetails";
import RestaurantInfo from "../pages/restaurant-information/restaurantInfo";
import TokenInfo from "../pages/token-generation/TokenInfo";
import OnBoardCustomer from "../pages/onboardcustomer/OnBoardCustomer";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../pages/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../pages/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../pages/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../pages/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../pages/chat/ChatPage"));
  // const GolfClub = lazy(() => import("../pages/golf-club/GolfClub"));
  const Membership = lazy(() => import("../pages/membership/Membership"));
  const Banners = lazy(() => import("../pages/banner"));
  const Booking = lazy(() => import("../pages/booking/Booking"));
  const AppAdmin = lazy(() => import("../pages/app-user/AppAdmin"));
  const AppCustomer = lazy(() => import("../pages/app-user/AppCustomer"));
  const TermsConditions = lazy(
    () => import("../pages/terms-conditions/TermsConditions"),
  );
  const PrivacyPolicy = lazy(
    () => import("../pages/privacy-policy/PrivacyPolicy"),
  );
  const PaymentPrivacy = lazy(
    () => import("../pages/payment-privacy/PaymentPrivacy"),
  );
  const PaymentTermsConditions = lazy(
    () => import("../pages/payment-terms-conditions/PaymentTermsConditions"),
  );
  const AboutApp = lazy(() => import("../pages/about-app/AboutApp"));
  const GolfHoles = lazy(() => import("../pages/holes/GolfHoles"));
  const FAQ = lazy(() => import("../pages/faq/FAQ"));
  const GolfClubOffers = lazy(
    () => import("../pages/golf-offer/GolfClubOffers"),
  );
  
  const Contactus = lazy(() => import("../pages/contactus/Contactus"));
  const ContactusWeb = lazy(() => import("../pages/contactus/ContactUsWeb"));
  const Inquiry = lazy(() => import("../pages/inquiry/Inquiry"));
  const MembershipDescription = lazy(
    () => import("../pages/membership-description/MembershipDescription"),
  );

  const routes = [
    { pathname: "/auth/*", element: <Navigate to="/dashboard" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: "/category", element: <Category /> },
    { pathname: "/category_restaurant_list", element: <RestaurantList /> },
    { pathname: "/category_hotel_list", element: <HotelList /> },
    { pathname: "/category-hotel-details", element: <HotelDetails /> },
    { pathname: "/health_care_list", element: <Health_care_list /> },
    { pathname: "/category_wellness_list", element: <WellnessList /> },
    { pathname: "/category-wellness-details", element: <WellnessDetails /> },
    { pathname: "/category-golf-club", element: <GolfClub /> },
    { pathname: "/category-golf-details", element: <GolfDetails /> },
    { pathname: "/squeez", element: <SquezzMe /> },
    { pathname: "/waitlist", element: <WaitingList /> },
    { pathname: "/preference", element: <Prefrence /> },
    { pathname: "/userPreference_details", element: <PreferenceDetails /> },
    { pathname: "/apparel_details", element: <ApparelDetails /> },
    { pathname: "/dashboard", element: <DashboardPage /> },
    {
      pathname: "/membership",
      element: isSuperAdmin ? <Membership /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/booking",
      element:  <Booking />,
    },
    {
      pathname: isAdmin ? "/concierge-user" : window.location.pathname == "/app-user/concierge-user" ? "/app-user/concierge-user" : "/app-user/app-admin",
      element: isSuperAdmin || isAdmin ? <AppAdmin /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/app-user/app-customer",
      element: isSuperAdmin ? <AppCustomer /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/app-user/business-inquiry",
      element: isSuperAdmin ? <OnBoardCustomer /> : <Navigate to="/error/404" />,
    },
    // {
    //   pathname: "/terms-conditions",
    //   element: isSuperAdmin ? (
    //     <TermsConditions />
    //   ) : (
    //     <Navigate to="/error/404" />
    //   ),
    // },
    // {
    //   pathname: "/privacy-policy",
    //   element: isSuperAdmin ? <PrivacyPolicy /> : <Navigate to="/error/404" />,
    // },
    // {
    //   pathname: "/payment-terms-conditions",
    //   element: <PaymentTermsConditions />,
    // },
    // {
    //   pathname: "/payment-privacy",
    //   element: isSuperAdmin ? <PaymentPrivacy /> : <Navigate to="/error/404" />,
    // },
    // {
    //   pathname: "/about-app",
    //   element: isSuperAdmin ? <AboutApp /> : <Navigate to="/error/404" />,
    // },
    { pathname: "/crafted/pages/profile/*", element: <ProfilePage /> },
    { pathname: "/crafted/pages/wizards/*", element: <WizardsPage /> },
    { pathname: "/crafted/widgets/*", element: <WidgetsPage /> },
    { pathname: "crafted/account/*", element: <AccountPage /> },
    // { pathname: "apps/chat/*", element: <ChatPage /> },
    { pathname: "*", element: <Navigate to="/error/404" /> }, // <----- Redirect to Page not found if no route match
    { pathname: "/category-golf-holes", element: <GolfHoles /> },
    { pathname: "/category-restaurant-details", element: <RestaurantInfo /> },
    { pathname: "/category-token-info", element: isSuperAdmin ? <TokenInfo /> : <Navigate to="/error/404" />},
    {
      pathname: "/faq",
      element: isSuperAdmin ? <FAQ /> : <Navigate to="/error/404" />,
    },
    { pathname: "/category-golf-offers", element: <GolfClubOffers /> },
    {
      pathname: "/banner",
      element: isSuperAdmin ? <Banners /> : <Navigate to="/error/404" />,
    },
    
    {
      pathname: "/booking",
      element: isSuperAdmin ? <Booking /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/userPreference",
      element: isSuperAdmin ? <Preference /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/subscription-info",
      element: isSuperAdmin ? (
        <SubscriptionInfo />
      ) : (
        <Navigate to="/error/404" />
      ),
    },
    {
      pathname: "/coupon",
      element: isSuperAdmin ? <Coupon /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/app-contactus/contactus",
      element: isSuperAdmin ? <Contactus /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/app-contactus/web-contactus",
      element: isSuperAdmin ? <ContactusWeb /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/app-contactus/inquiry",
      element: isSuperAdmin ? <Inquiry /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/description-membership",
      element: isSuperAdmin ? (
        <MembershipDescription />
      ) : (
        <Navigate to="/error/404" />
      ),
    },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {routes.map((route) => (
          <Route
            key={route.pathname}
            path={route.pathname}
            element={<SuspensedView>{route.element}</SuspensedView>}
          />
        ))}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
