import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import { addRestaurantProvider, getAllPaymentProviderInfoBasedOnCategory, getPaymentProvider, getRestaurantInfo , getStripeAccountLink  } from "../../api";
import { useMutation } from "react-query";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Select from 'react-select'


interface PaymentProvider {
  _id: string;
  name: string;
  IsDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface PaymentProviderField {
  provider: string;
  merchantID?: string;
  publicPrivateKey?: string;
  secretKey?: string;
  locationId?: string;
  userApiKey?: string;
  userId?: string;
  developerId?: string;
  partnerID?: string;
  feeType: string;
  feeAmount: string;
  email?: string;
  stripeLink?: string;
  selectedPaymentProvider?: any
}

interface ContractorField {
  contractorName: string;
  contractorID: string;
  splitPercentage: string;
  anonymousName: string;
}

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: any;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditProvider = ({ show, handleClose, editData }: Props) => {
  // State declarations
  const [selectedBookingProvider, setSelectedBookingProvider] = useState<string>("");
  const [bookingProvider, setBookingProvider] = useState<any>();
  const [paymentProviders, setPaymentProviders] = useState<PaymentProvider[]>([]);
  const [contractorProviders, setContractorProviders] = useState<PaymentProvider[]>([]);
  const [paymentProviderFields, setPaymentProviderFields] = useState<PaymentProviderField[]>([{
    provider: "",
    feeType: "$",
    feeAmount: "",
  }]);
  const [contractorFields, setContractorFields] = useState<ContractorField[]>([{
    contractorName: "",
    contractorID: "",
    splitPercentage: "0",
    anonymousName: ""
  }]);
  const [isAddContractorEnabled, setIsAddContractorEnabled] = useState(false);
  // For Submit
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isLoadingAccountLink, setIsLoadingAccountLink] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);

  const {
    mutateAsync: getStripeLink,
    isLoading: isGettingStripeLink,
  } = useMutation("get-stripe-link", getStripeAccountLink);
// For Submit
const validateForm = () => {
  // Check if "fortis" is the selected payment provider
  const isFortisSelected = paymentProviderFields.some(provider => provider.provider === "fortis");
  
  // If "fortis" is selected, enable the submit button without validation
  if (isFortisSelected) {
    setIsSubmitDisabled(false);
    return;
  }
  
  // Otherwise, proceed with normal validation
  const isProviderSelected = paymentProviderFields.some(provider => provider.provider !== "");
  // const isContractorSelected = contractorFields.some(field => field.contractorName !== "" && field.contractorID !== "");
  setIsSubmitDisabled(!(isProviderSelected));
};
  // Mutations
  const {
    mutateAsync: getRestaurantData,
    data: restaurantData,
    isLoading: isGettingRestaurantData,
  } = useMutation("get-golf", getAllPaymentProviderInfoBasedOnCategory);

  const {
    data,
    isLoading,
    error,
    mutateAsync: getproviderData
  } = useMutation("paymentProvider", getPaymentProvider);

  const {
    mutateAsync: addRestaurantCategory,
    isLoading: isAddingGolf,
  } = useMutation("add-restaurant", addRestaurantProvider);

  // Initial data fetch
  useEffect(() => {
    const getGolf = async () => {
      if (editData?._id) {
        await getRestaurantData(editData._id);
      }
    };
    getGolf();
  }, [editData]);

  useEffect(() => {
    if (editData?._id) {
      getproviderData({ catId: typeof(editData?.cateId) === "string" ? editData?.cateId :  editData?.cateId?._id });
    }
  }, [editData]);

 // For Submit
  useEffect(() => {
    validateForm();
  }, [paymentProviderFields, contractorFields]);

useEffect(() => {
  if (restaurantData?.data) {
    const paymentInfoArray = restaurantData.data.paymentInfo || [];
    const bookingInfoArray = restaurantData.data.bookingInfo || [];

    // Initialize payment providers
    const initialPaymentProviders = paymentInfoArray.flatMap((paymentInfoObj: any) => {
      return Object.entries(paymentInfoObj).map(([providerName, providerData]: [string, any]) => ({
        provider: providerName,
        merchantID: providerData.fields?.accountId || '',
        email:providerData.fields?.email || '',
        stripeLink: providerData.fields?.stripeLink || '',
        publicPrivateKey: providerData.fields?.public_private_key || '',
        secretKey: providerData.fields?.secret_key || '',
        locationId: providerData.fields?.accountId || '',
        userApiKey: providerData.fields?.publicPrivateKey || '',
        userId: providerData.fields?.secretKey || '',
        developerId: providerData.fields?.developerId || '',
        feeType: providerData.fields.flatFees !== undefined ? '$' : '%',
        feeAmount: (providerData.fields.flatFees || providerData.fields.perFlatFees || '').toString(),
        selectedPaymentProvider: {
          value: providerName,
          label: providerName
        }
      }));
    });

    setPaymentProviderFields(initialPaymentProviders.length > 0 ? initialPaymentProviders : [{
      provider: "",
      feeType: "$",
      feeAmount: "",
    }]);

    // Initialize contractor fields
    const firstPaymentInfoObj = paymentInfoArray[0] || {};
    const firstProviderName = Object.keys(firstPaymentInfoObj)[0];
    const firstProvider = firstPaymentInfoObj[firstProviderName];
    const contractorFieldsData = firstProvider?.fields?.contractorFields || [];

    if (contractorFieldsData.length > 0) {
      setContractorFields(contractorFieldsData.map((field: any) => ({
        contractorName: field.contractorName,
        contractorID: field.contractorFieldId,
        splitPercentage: field.contractorPer,
        anonymousName: field.name || '',
      })));
    }

    // Initialize booking provider
    const firstBookingInfoObj = bookingInfoArray[0] || {};
    const bookingProviderName = Object.keys(firstBookingInfoObj)[0] || "";
    setSelectedBookingProvider(bookingProviderName);
    if (contractorFieldsData.length > 0) {
     // Enable the add button after adding a new field
     setIsAddContractorEnabled(true);  
    }
    // Set initial values for formik
    formik.setValues({
      booking_provider: bookingProviderName,
      venueID: firstBookingInfoObj[bookingProviderName]?.fields?.venueId || "",
      perForMerchant:restaurantData?.data?.perForMerchant || 0,
    });
  }
}, [restaurantData]);


  useEffect(() => {
    if(data && selectedBookingProvider){
      const filteredBookingProviderValue = data?.bookingProviders?.filter((providerName: any) => providerName?.name == selectedBookingProvider)
      const bookingProviderValue = {
        value: filteredBookingProviderValue[0]?.name,
        label: `${filteredBookingProviderValue[0]?.name}`,
      }
      setBookingProvider(bookingProviderValue)
    }
  },[data, selectedBookingProvider])

  useEffect(() => {
    if (data?.paymentProviders) {
      setPaymentProviders(data.paymentProviders);
    }
    if (data?.contractorProviders) {
      setContractorProviders(data.contractorProviders);
    }
  }, [data]);


  const handleRemovePaymentProvider = (indexToRemove: number) => {
    if (paymentProviderFields.length === 1) return;
    setPaymentProviderFields(paymentProviderFields.filter((_, index) => index !== indexToRemove));
  };


  const handleCopyToClipboard = async (text: string, index: number) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedIndex(index);
      snackActions.success("Copied to clipboard!");
      
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    } catch (err) {
      snackActions.error("Failed to copy to clipboard");
    }
  };


  const handleEmailSubmit = async (index: number, email: string) => {
    try {
      setIsLoadingAccountLink(true);
      const response = await getStripeLink({ email });
      if (response?.status && response?.data?.stripeAccountUrl) {
        const updatedFields = paymentProviderFields.map((providerField, idx) => {
          if (idx === index) {
            return {
              ...providerField,
              stripeLink: response.data.stripeAccountUrl,
              merchantID:response.data.stripeAccountId,
              email:email
            };
          }
          return providerField;
        });
        setPaymentProviderFields(updatedFields);
        snackActions.success("Stripe account link generated successfully!");
      } else {
        snackActions.error(response?.message ?? "Failed to generate Stripe account link");
      }
    } catch (error) {
      snackActions.error("An error occurred while generating Stripe account link");
    } finally {
      setIsLoadingAccountLink(false);
    }
  };
  const handlePaymentProviderChange = (index: number, field: string, value: string) => {
    const updatedFields = paymentProviderFields.map((providerField, idx) => {
      if (idx === index) {
        if (field === 'provider') {
          return {
            ...providerField,
            [field]: value,
            merchantID: '',
            publicPrivateKey: '',
            secretKey: '',
            locationId: '',
            userApiKey: '',
            userId: '',
            developerId: '',
            email: '',
            stripeLink: '',
            selectedPaymentProvider:{
              value: value,
              label: value
            }
          };
        }
        return { ...providerField, [field]: value };
      }
      return providerField;
    });
    setPaymentProviderFields(updatedFields);
    // For Submit
    validateForm();
  };

  const handleAddContractorField = () => {
    setContractorFields([
      ...contractorFields,
      { contractorName: "", contractorID: "", splitPercentage: "0", anonymousName: "" },
    ]);
  // Disable the add button after adding a new field
  setIsAddContractorEnabled(false);
  };

const handleRemoveContractorField = (indexToRemove: number) => {
  if (contractorFields.length === 1) return;
  setContractorFields(contractorFields.filter((_, index) => index !== indexToRemove));
};

const handleBookingProviderChange = (e: any) => {
  const provider = e.value;
  setSelectedBookingProvider(provider);
  setBookingProvider(e)
  formik.setFieldValue('booking_provider', provider);
};


// Formik initialization
const formik = useFormik({
  initialValues: {
    booking_provider: "",
    venueID: "",
    perForMerchant: restaurantData?.data?.perForMerchant || 0,
  },
  onSubmit: async (values) => {
 // Transform payment provider fields into the required format
 const paymentInfo = paymentProviderFields.map((provider) => {
  const providerData: any = {
    [provider.provider]: {
      fields: {
        ...(provider.provider === "fiserv" && {
          accountId: provider.merchantID,
          public_private_key: provider.publicPrivateKey,
        }),
        ...(provider.provider === "shift4" && {
          secret_key: provider.secretKey,
        }),
        ...(provider.provider === "fortis" && {
          developerId: provider.developerId,
          publicPrivateKey: provider.userApiKey,
          secretKey: provider.userId,
          accountId: provider.locationId,
        }),
        
        ...(provider.provider === "stripe" && {
          email: provider.email,
          stripeLink: provider.stripeLink,
          accountId: provider.merchantID,
        }),
        ...(provider.feeType === "$" 
          ? { flatFees: Number(provider.feeAmount) } 
          : { perFlatFees: Number(provider.feeAmount) }),
        // Move contractorFields inside the fields object
        ...(provider.provider !== "fortis" && {
        contractorFields: contractorFields.map((field) => ({
          contractorFieldId: field.contractorID,
          contractorName: field.contractorName,
          contractorPer: Number(field.splitPercentage),
          ...(field.contractorName.toLowerCase() === "Brand Name" && { name: 'Brand Name' }),
        })),
      }),
      },
    },
  };
  return providerData;
});

const bookingProviderData = {
  [values.booking_provider]: {
    fields: {
      venueId: values.venueID,
      accountId: values.booking_provider,
    },
  },
};

  
    try {
      const response = await addRestaurantCategory({
        categoryId: editData?.cateId?._id ?? editData?.cateId,
        itemId: editData?._id,
        paymentInfo,
        bookingInfo: [bookingProviderData],
        booking_provider: values.booking_provider,
        perForMerchant: values.perForMerchant,
      });

      if (response?.status) {
        snackActions.success(response?.message ?? "Restaurant Added Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    } catch (error) {
      snackActions.error("An error occurred while saving the data.");
    }
  },
});

const isFortiSelected = paymentProviderFields[0].provider === "fortis" && data?.bookingProviders && data.bookingProviders.length >= 0 
// Render method
const bookingProviderOptions = data?.bookingProviders?.map((provider: any) => ({
  value: provider?.name,
  label: `${provider?.name}`,
}));

const paymentProviderOptions = paymentProviders?.map((paymentProvider: any) => ({
  value: paymentProvider?.name,
  label: `${paymentProvider?.name}`,
}))


return createPortal(
  <Modal
    id="kt_modal_create_app"
    tabIndex={-1}
    aria-hidden="true"
    dialogClassName="modal-dialog modal-dialog-centered mw-900px scroll"
    show={show}
    onHide={() => handleClose(false)}
    backdrop="static"
    size="xl" 
  >
    <div className="modal-header">
      <h2>Add/Edit Payment & Booking Provider</h2>
      <div
        className="btn btn-sm btn-icon btn-active-color-primary"
        onClick={() => handleClose(false)}
      >
        <KTIcon className="fs-1" iconName="cross" />
      </div>
    </div>
    <div style={{ 
      // height: isFortiSelected ? "calc(100vh - 48vh)" : "calc(100vh - 35vh)", 
      maxHeight:"80vh",
      overflowY: "auto" 
                }}>
    <div className="p-3 p-sm-6">
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <Container fluid>

              {/* Booking Provider */}
              {data?.bookingProviders && data.bookingProviders.length > 0 && (
                <>
      
              <Row style={{paddingBottom: "20px"}}>
              <Col xs={12} md={6} style={{paddingBottom: "20px"}} >
                  <label className="form-label fw-bold">
                    Booking Provider
                  </label>
                  {/* <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("booking_provider")}
                    onChange={handleBookingProviderChange}
                  >
                    <option value="">Select Booking Provider</option>
                    {data?.bookingProviders?.map((provider: any) => (
                      <option value={provider.name} key={provider.name}>
                        {provider.name}
                      </option>
                    ))}
                  </select> */}

<Select
                  options={bookingProviderOptions}
                  value={bookingProvider}
                  onChange={handleBookingProviderChange}
                  placeholder="Select Booking Provider"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                </Col>

                {selectedBookingProvider.toLocaleLowerCase() === "mozrest".toLocaleLowerCase() && (
                  <>
                       <Col xs={12} md={6} style={{paddingBottom: "20px"}}>
                      <label className="form-label fw-bold">
                        Venue ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Venue ID"
                        {...formik.getFieldProps("venueID")}
                      />
                    </Col>
                  </>

                )}
                  {selectedBookingProvider.toLocaleLowerCase() === "book4times".toLocaleLowerCase() && (
                  <>
                       <Col xs={12} md={6}  style={{paddingBottom: "20px"}}>
                      <label className="form-label fw-bold">
                        Location ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Location ID"
                        {...formik.getFieldProps("venueID")}
                      />
                    </Col>
                  </>

                )}

<div className="border-top border-dark"></div>
              </Row>
              </>
             )}
              <Row>
                     <Col xs={12}>
                     {/* <h4 className="mb-4">Payment Provider {index + 1}</h4> */}
                      <h4 >Payment Provider </h4>
                      </Col>
                      <Col xs={12} md={6} className="mt-4 mb-4">
                      <label className="form-label fw-bold">
                      Split percentage for merchant
                      </label>
                        <input
                        type="number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Split percentage for merchant"
                        {...formik.getFieldProps("perForMerchant")}
                      />
                      
                    </Col>
                    </Row>
          {/* Payment Provider Fields */}
          {paymentProviderFields.map((provider, index) => (
            <Row key={index} className="pb-4 mb-4 mt-5">
              {/* <Col xs={12}> */}
                {/* <h4 className="mb-4">Payment Provider {index + 1}</h4> */}
                {/* <h4 className="mb-4">Payment Provider </h4> */}
              {/* </Col> */}
              <Col xs={12} md={6} className="pt-3">
                <label className="form-label fw-bold">Select Payment gateway</label>
                {/* <select
                  className="form-select form-select-solid form-select-lg"
                  value={provider.provider}
                  onChange={(e) => handlePaymentProviderChange(index, 'provider', e.target.value)}
                >
                  <option value="">Select Payment gateway</option>
                  {paymentProviders?.map((p: PaymentProvider) => (
                    <option value={p.name} key={p._id}>{p.name}</option>
                  ))}
                </select> */}

<Select
                  options={paymentProviderOptions}
                  value={provider.selectedPaymentProvider}
                  onChange={(selectedOption: any) => handlePaymentProviderChange(index, 'provider', selectedOption.value)}
                  placeholder="Select Payment Provider"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </Col>

              {/* Provider-specific fields */}
              {provider.provider === "fiserv" && (
                <>
                   <Col xs={12} md={6} className="pt-3">
                    <label className="form-label fw-bold">Merchant ID</label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      value={provider.merchantID || ''}
                      onChange={(e) => handlePaymentProviderChange(index, 'merchantID', e.target.value)}
                    />
                  </Col>
                  <Col xs={12} md={6} className="pt-3   mt-5">
                    <label className="form-label fw-bold">Private Key</label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      value={provider.publicPrivateKey || ''}
                      onChange={(e) => handlePaymentProviderChange(index, 'publicPrivateKey', e.target.value)}
                    />
                  </Col>
                </>
              )}

            {provider.provider === "shift4" && (
               <Col xs={6} md={6} className="pt-3">
                  <label className="form-label fw-bold">Secret Key</label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    value={provider.secretKey || ''}
                    onChange={(e) => handlePaymentProviderChange(index, 'secretKey', e.target.value)}
                  />
                </Col>
              )}
             
             {provider.provider.toLowerCase() === "stripe" && (
                  <>
                    <Col xs={12} className="pt-3 col-md-4 col-12">
                      <label className="form-label fw-bold">Email</label>
                      <div className="d-flex gap-2">
                        <input
                          type="email"
                          className="form-control form-control-lg form-control-solid"
                          value={provider.email || ''}
                          onChange={(e) => handlePaymentProviderChange(index, 'email', e.target.value)}
                          placeholder="Enter email for Stripe account"
                        />
                          </div>
                          </Col>
                          <Col xs={12} md={1} className="pt-3 col-md-2 col-12" >
                        <Button
                          variant="primary"
                          style={{marginTop: "2rem"}}
                          onClick={() => handleEmailSubmit(index, provider.email || '')}
                          disabled={!provider.email || isLoadingAccountLink}
                        >
                          {isLoadingAccountLink ? 'Loading...' : <CheckIcon/>}
                        </Button>
                    </Col>
                    {/* {provider.stripeLink && ( */}
                      <Col xs={12} md={6} className="pt-3">
                        <label className="form-label fw-bold">Stripe Account Link</label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={provider.stripeLink}
                          readOnly
                        />
                          
                      </Col>
                      <Col xs={12} md={6} className="pt-3">
                        <Button
                            variant="primary"
                            onClick={() => handleCopyToClipboard(provider.stripeLink || '', index)}
                            className="d-flex align-items-center"
                            style={{ minWidth: '100px' , marginTop: "2rem" }}
                          >
                            <ContentCopyIcon className="me-1" />
                            {copiedIndex === index ? 'Copied!' : 'Copy'}
                          </Button>
                      </Col>
                    {/* )} */}
                  </>
                )}
                {provider.provider === "fortis" && (
      <>
            <Col xs={6} md={3} className="pt-3">
          <label className="form-label fw-bold">Merchant Location ID</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            value={provider.locationId || ''}
            onChange={(e) => handlePaymentProviderChange(index, 'locationId', e.target.value)}
          />
        </Col>
        <Col xs={6} md={3} className="pt-3">
          <label className="form-label fw-bold">Merchant API Key</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            value={provider.userApiKey || ''}
            onChange={(e) => handlePaymentProviderChange(index, 'userApiKey', e.target.value)}
          />
        </Col>
        <Col xs={6} md={3} className="pt-2  mt-5">
          <label className="form-label fw-bold">Merchant User ID</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            value={provider.userId || ''}
            onChange={(e) => handlePaymentProviderChange(index, 'userId', e.target.value)}
          />
        </Col>
        <Col xs={6} md={3} className="pt-2  mt-5">
          <label className="form-label fw-bold">Merchant Developer ID</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            value={provider.developerId || ''}
            onChange={(e) => handlePaymentProviderChange(index, 'developerId', e.target.value)}
          />
        </Col>
      </>
    )}


              {/* Fee Type and Amount for each provider */}
             <Col xs={6} md={3} className="pt-3 pt-md-6" style={{ display: "none"}}>
                <label className="form-label fw-bold">Fee Type</label>
                <select
                  className="form-select form-select-solid form-select-lg"
                  value={provider.feeType}
                  onChange={(e) => handlePaymentProviderChange(index, 'feeType', e.target.value)}
                >
                  <option value="$">$</option>
                  <option value="%">%</option>
                </select>
              </Col>

              <Col xs={6} md={3} className="pt-3 pt-md-6" style={{ display: "none"}}>
                <label className="form-label fw-bold">
                  {provider.feeType === "$" ? "Flat Fee Amount" : "Percentage Fee"}
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid"
                  value={provider.feeAmount}
                  onChange={(e) => handlePaymentProviderChange(index, 'feeAmount', e.target.value)}
                />
              </Col>

              {paymentProviderFields.length > 1 && (
                  <Col xs={12} md={1} className="pt-3 pt-md-6 d-flex align-items-end">
                  <Button
                    variant="danger"
                    className="p-3"
                    onClick={() => handleRemovePaymentProvider(index)}
                  >
                    <KTIcon iconName="trash" className="fs-2" />
                  </Button>
                </Col>
              )}
            </Row>
          ))}

<div className="border-top"></div>
          {/* <Button
            variant="primary"
            className="mb-4"
            onClick={handleAddPaymentProvider}
          >
            Add Payment Provider
          </Button>  */}
          </Container>
          <div className="w-100 d-flex p-5 align-items-center justify-content-end mt-5">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            {/* <Button type="submit" size="lg" variant="primary"> */}
            <Button type="submit" size="lg" variant={isSubmitDisabled ? "secondary" : "primary"}  disabled={isSubmitDisabled} >
              {/* {isAddingEditing ? "Loading..." : "Submit"} */}
              Submit
            </Button>
          </div>
        </form>
      </div>
</div>

    </Modal>,
    modalsRoot,
  );
};

export default AddEditProvider;
