import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { KTCard, KTIcon } from "../../_metronic/helpers";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { PageTitle } from "../../_metronic/layout/core";
import { activeDeActiveGolfClub, deleteGolfClub, deleteRestrictedSlot, deleteWidgetDetails, editAdmin, getAllHotelList, getGolfClub, getGolfInfo, getHotelInfo, getRestaurantInfo, getSlotDetailsById, getWellnessInfo, getWidgetDetails } from "../../api";
import AddEditHoles from "../../components/holes/AddEditHoles";
import MuiTable from "../../components/table/MuiTable";
import AddEditThirdPartyAPI from "../../components/third-party-api/AddEditThirdPartyAPI";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import AddEditSlot from "../../components/slot-restriction/AddEditSlot";
import { parseDateWithoutTimezone } from "../../helpers/commonFunctions";
import RestaurantIcon from "@mui/icons-material/GolfCourse";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { de } from "date-fns/locale";
import TokenGeneration from "../../components/slot-restriction/TokenGeneration";
import InfoIcon from "@mui/icons-material/Info"; // Import the Info Icon
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Import copy icon

const TokenInfo: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [generateTokenModal, setShowGenerateTokenModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false);
  const [showGolfImgPopup, setShowGolfImgPopup] = useState<boolean>(false);
  const [showAddEditAPIPopup, setShowAddEditAPIPopup] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [selectedGolfImg, setSelectedGolfImg] = useState<string>("");
  const [showInfoModal, setShowInfoModal] = useState(false); // New state for the info modal

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        snackActions.success('Widget code copied to clipboard!');
      })
      .catch(() => {
        snackActions.error('Failed to copy.');
      });
  };
  
  const tableRef = useRef();
  const location: any = useLocation();
  const golfDetailInfo = location?.state;
  const isForTokenGeneration = location?.state?.isForTokenGeneration;
  const navigate = useNavigate();

  const {
    mutateAsync: getRestaurantData,
    data: restaurantData,
    isLoading: isGettingRestaurantData,
    error: restaurantError,
  } = useMutation("get-restaurant", getRestaurantInfo);

  const {
    mutateAsync: getHotelData,
    data: HotelData,
    isLoading: isGettingHotelData,
    error: hotelError,
  } = useMutation("get-hotel", getHotelInfo);

  const {
    mutateAsync: getGolfData,
    data: golfData,
    isLoading: isGettingGolfData,
    error: golfError,
  } = useMutation("get-golf", getGolfInfo);

  const {
  mutateAsync: getWellnessData,
  data: wellnessData,
  isLoading: isGettingWellnessData,
  error: wellnessError,
} = useMutation("get-wellness", getWellnessInfo);

  const categoryData = HotelData || restaurantData || golfData || wellnessData

  const { data, isLoading, mutateAsync: getSlotdetails } = useMutation("slot-details", getSlotDetailsById);
  const { data:widgetDetails, isLoading: isLoadingWidget, mutateAsync: getWidgetDetailsById } = useMutation("widget-details", getWidgetDetails);

  const {
    mutateAsync: deleteWidgetData,
    isLoading: isdeletingWidget,
    error: deleteWidgetAPIError,
  } = useMutation("delete-widget", deleteWidgetDetails);

  useEffect(() => {
    getGolf();
  }, []);

  const [widgetTableOptions, setWidgetTableOptions] = useState({
    offset: 0,
    limit: 10,
    restaurantId: categoryData?.data?._id,
    totalRowCount: undefined
  });

  useEffect(() => {
    if (categoryData?.data?._id) {
      getWidgetDetailsById({ ...widgetTableOptions, restaurantId: categoryData?.data?._id, catId: golfDetailInfo?.restaurantData?.cateId})
    }
  }, [categoryData?.data?._id])


  const htmlString = restaurantData?.data?.description
    ? charValidate(restaurantData?.data?.description, 200)
    : "";

  const getGolf = async () => {
    if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_REST_CATE){
    await getRestaurantData(golfDetailInfo?.restaurantData?._id ?? "");
    }
    else if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_HOTEL_CATE){
    await getHotelData(golfDetailInfo?.restaurantData?._id ?? "")
    }
    else if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_GOLF_CATE){
    await getGolfData(golfDetailInfo?.restaurantData?._id ?? "")
    }
    else if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_SALOON_CATE){
    await getWellnessData(golfDetailInfo?.restaurantData?._id ?? "")
    }
  };

  const widgetColumns: GridColDef[] = [
    {
      field: "token",
      headerName: "Token",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Tooltip
          title={
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '200px' }}>
              {params.row.token ?? "-"}
            </div>
          }
          arrow
          placement="top"
          classes={{ tooltip: 'custom-tooltip' }}
        >
          <div style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'normal', // Allows text to wrap
            display: '-webkit-box',
            WebkitLineClamp: 3, // Number of lines to show
            WebkitBoxOrient: 'vertical'
          }}>
            {params.row.token ?? "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "websiteUrl",
      headerName: "Website URL",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => data?.row?.websiteUrl ?? "-",
    },
    {
      field: "widgetUrl",
      headerName: "Widget URL",
      flex: 2,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Tooltip
          title={
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '200px' }}>
              {params.row.widgetUrl ?? "-"}
            </div>
          }
          arrow
          placement="top"
          classes={{ tooltip: 'custom-tooltip' }}
        >
          <div style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical'
          }}>
            {params.row.widgetUrl ?? "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.7,
      headerAlign: "right",
      align: "right", 
      cellClassName:"token-actions-align",
      renderCell: (data) => (
        <Box display="flex" alignItems="center" justifyContent="flex-end" className="actions-cell"> {/* Align items to the right */}
        <Tooltip title="View Info">
            <InfoIcon
              onClick={() => {
                setSelectedRow(data.row); // Set selected row data
                setShowInfoModal(true);   // Open the info modal
              }}
              sx={{ fontSize: 22, cursor: "pointer" ,  mr: 1.7, }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data?.row);
                setShowGenerateTokenModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    }
    
  ];

const InfoModal = () => {
  const iframeCode = `<iframe width="100%" height="100%" src="${selectedRow?.widgetUrl ?? "-"}?token=${selectedRow?.token ?? "-"}" title="Widget" ></iframe>`;
  
  return (
    <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Widget Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>HTML/CSS Code:</strong> </p>
        <div>
          
              <pre><code>&lt;iframe
              width=&quot;100%&quot;
              height=&quot;100%&quot;
              src=&quot;{selectedRow?.widgetUrl ?? "-"}&quot;
              title=&quot;Widget&quot;
              &gt;&lt;/iframe&gt;</code></pre>
        </div>
        <Button
          variant="primary"
          onClick={() => copyToClipboard(iframeCode)} // Call the copy function with the iframe code
          className="d-flex align-items-center justify-content-center"
        >
          <ContentCopyIcon sx={{ fontSize: 20, cursor: "pointer", mr: 1 }} />
          Copy Widget Code
        </Button>
      </Modal.Body>
   
    </Modal>
  );
};
  const handleTokenModalClose = (isSubmit: boolean) => {
    setShowGenerateTokenModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getGolf();
    }
  };

  const deleteConfirmModal = (data: any) => {
    return (
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-400px scroll"
        show={showDeleteConfirmModal}
        onHide={handleCloseDelete}
        backdrop="static"
      >
        <div className="modal-header">
          <h2>Delete Widget</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleCloseDelete}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="p-5">
            <h4>Are you sure, you want to delete the widget?</h4>
          </div>
          <div className="w-100 d-flex p-5 align-items-center  justify-content-center">
            <Button
              disabled={isdeletingWidget}
              onClick={handleCloseDelete}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button
              disabled={isdeletingWidget}
              onClick={deleteWidget}
              size="lg"
              variant="primary"
            >
              { isdeletingWidget ? "Loading..." : "Submit"}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const golfImagePopup = () => (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px scroll"
      show={showGolfImgPopup}
      onHide={() => setShowGolfImgPopup(false)}
      backdrop={true}
    >
      <Image src={selectedGolfImg} fluid />
    </Modal>
  );

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setShowGenerateTokenModal(false)
  };

  const deleteWidget = async () => {
    let response = await deleteWidgetDetails({
      catId: golfDetailInfo?.restaurantData?.cateId,
      itemId: categoryData?.data?._id,
      widgetId: selectedRow?._id
    });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getGolf();
    } else {
      snackActions.error(response?.message);
    }
  };


  const handleBackClick = () => {
    if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_REST_CATE){
    navigate("/category_restaurant_list");
    }
    else if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_HOTEL_CATE){
    navigate("/category_hotel_list")
    }
    else if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_GOLF_CATE){
    navigate("/category-golf-club")
    }
    else if(golfDetailInfo?.restaurantData?.cateId == process.env.REACT_APP_ID_SALOON_CATE){
    navigate("/category_wellness_list")
    }
  };

  const getWidget = async (
    query:
      | {
        offset: number;
        limit: number;
      }
      | undefined
  ) => {
    let response = await getWidgetDetailsById(query)
    if (response?.status) {
      setWidgetTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          restaurantId: categoryData?.data?._id,
          totalRowCount: response?.data?.total,
        };
      });
    }
  };

  useEffect(() => {
    if (widgetDetails) {
      setWidgetTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: widgetDetails?.offset,
          limit: widgetDetails?.limit,
          restaurantId: categoryData?.data?._id,
          totalRowCount: widgetDetails?.total,
        };
      });
    }
  }, [widgetDetails])

  const handleTokenTablePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        Number(pageSize == widgetTableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0),
      limit: pageSize ?? 10,
      restaurantId: categoryData?.data?._id
    };
    getWidget(query);
  };

  const TokenDetailsCard = () => {
    return (
      <Card style={{ width: "100%", boxShadow: "none"}} className="mb-4">
        <Card.Body>
          <Row className="mt-5">
            <Card.Subtitle style={{color:"black !important", fontSize:"14.95px"}}>
              Token Information:
            </Card.Subtitle>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const handleAddEditAPIModalClose = () => {
    setShowAddEditAPIPopup(false);
  };

  return (
    <Card className="p-6">
      <PageTitle>{golfDetailInfo?.restaurantData?.name ?? ""}</PageTitle>
      <>
        <KTCard>
        <MuiTable
            columns={widgetColumns}
            data={widgetDetails?.widgetDetails}
            tableRef={tableRef}
            loading={isGettingRestaurantData || isGettingHotelData || isGettingGolfData || isGettingWellnessData}
            isBackEnable={true}
            handleBackClick={handleBackClick}
            tableOptions={widgetTableOptions}
            handlePagination={handleTokenTablePagination}
            handleGenerateTokenClick= {() =>{
              setShowGenerateTokenModal(true);
              setSelectedRow(null)
            }}
            isForTokenTable={true}
            isForRestaurant={true}
            isForTokenGeneration= {true}
          >
            <TokenDetailsCard />
            </MuiTable>
        </KTCard>
        
        {generateTokenModal && (
          <TokenGeneration
            editData={selectedRow}
            show={generateTokenModal}
            handleClose={handleTokenModalClose}
            restaurantData={categoryData?.data}
            categoryId={golfDetailInfo?.restaurantData?.cateId}
          />
        )}
        {showDeleteConfirmModal && deleteConfirmModal(selectedRow)}
        {showGolfImgPopup && golfImagePopup()}
        {showInfoModal && <InfoModal />} {/* Render the info modal */}
      </>
      
    </Card>
  );
};

export default TokenInfo;
