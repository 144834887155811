import { format } from 'date-fns';

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const URLRegex =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

 const webURLRegex = /^(http|https):\/\/[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*(:\d+)?(?:\/[\w .-_]*)*\/?$/;
  

  const parseDateWithoutTimezoneMMDDYY = (dateString:any) => {
    if (!dateString) return "-";

    const date = new Date(dateString);
    
    // Format to MM/DD/YYYY
    const formattedDate = format(date, 'MM/dd/yyyy');
    
    return formattedDate; 
};

enum RoleType {
  ADMIN = 'admin',
  CONCIERGEUSER = 'concierge user'
} 

export { URLRegex, passwordRegex , webURLRegex , parseDateWithoutTimezoneMMDDYY, RoleType};
