import { Box, Tooltip } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Card from "react-bootstrap/Card";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import React, { useState } from "react";
import './CategoryData.css';

interface UserPreferencesCategoryCardViewProps {
  category: any;
  isSelected: boolean;
  onClick: (categoryId: any) => void;
}

const UserPreferencesCategoryCardView: React.FC<
  UserPreferencesCategoryCardViewProps
> = ({ category, isSelected, onClick }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleClick = (categoryId: any) => {
    setSelectedCategory(categoryId);
  };
  return (
    <Box pb={1}>
      <Card
        className="cursor-pointer category-card"
        style={{
          backgroundColor:
            category._id === process.env.REACT_APP_ID_REST_CATE ||
            category._id === process.env.REACT_APP_ID_GOLF_CATE
              ? "white"
              : "#f0f0f0",
          height: "100px",
          marginBottom: "20px",
          position: "relative",
          border:
            isSelected 
              ? "2px solid skyblue"
              : "none",
        }}
        onClick={() => onClick(category._id)}
      >
        <Card.Body
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div style={{ flex: "1", overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Card.Title>{category.name}</Card.Title>
              <div
                style={{
                  width: "100%",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                <Card.Text>{category.description}</Card.Text>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default UserPreferencesCategoryCardView;
