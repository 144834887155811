import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { addEditInterest, addEditInterestApparel, getCategories } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: {
    _id: string;
    name: string;
    type: string;
    catId: string;
    value: string;
  };
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditPreference = ({ show, handleClose, editData }: Props) => {
  const location: any = useLocation();
  const info = location?.state;
  const [isApparel, setIsApparel] = useState<string>("false");

  useEffect(() => {
    const apparelTypes = ["Shoes","Shorts", "Cloth", "Polo", "Hats"];
    setIsApparel(apparelTypes.includes(info?.categoryValue?.catKey) ? "true" : "false");
  }, [info?.catKey]);

  const { mutateAsync: addEditData, isLoading: isAddingEditing } = useMutation(
    "add-edit",
    addEditInterest,
  );
  const { mutateAsync: addEditDataApparel, isLoading: isAddingEditingApparel } = useMutation(
    "add-edit-Apparel",
    addEditInterestApparel,
  );
  const categoryId = localStorage.getItem('selectedCategory')
  const getInitialValues = () => {
    if (isApparel === "true") {
      return {
        name: editData?.value ? editData?.value?.replace(/^\s+|\s+$/g, "") : "",
        catType: "Brand",
        type: editData.type
      };
    }
    return {
      name: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      catId: categoryId,
      // type: editData.type
    };
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim() // Remove leading and trailing whitespace
      .required("Name is required"),
    // .test(
    //   "no-blank-spaces",
    //   "Blank spaces are not allowed",
    //   (value) => !/\s/.test(value),
    // ),
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    enableReinitialize: true, 
    onSubmit: async (values) => {
      if (isApparel === "true") {
        let bodyParams = {
          _id: editData?._id ? editData?._id : undefined,
          value: values?.name
            ? values?.name?.replace(/^\s+|\s+$/g, "")
            : "",
          type: editData.type,
          // catId: values?.catId,
          catType: "Brand",
        }; 
        let response = await addEditDataApparel(bodyParams) ;
        if (response?.status) {
          snackActions.success(response?.message ?? "Added/Edited Successfully!");
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.message ?? "Something went wrong!");
        }
      }else{
        let bodyParams = {
          _id: editData?._id ? editData?._id : undefined,
          name: values?.name
            ? values?.name?.replace(/^\s+|\s+$/g, "")
            : "",
          type: editData.type,
          catId: values?.catId,
        };
        let response =  await addEditData(bodyParams);
        if (response?.status) {
          snackActions.success(response?.message ?? "Added/Edited Successfully!");
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.message ?? "Something went wrong!");
        }
      }
    },
  });

  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    refetch: refetchCategories,
  } = useQuery("category", getCategories);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData?._id ? "Edit" : "Add"} {editData.type}</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="pt-6">
            <label className="form-label fw-bold">Name</label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Name"
              aria-label="Name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end mt-5">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingEditing || isAddingEditingApparel ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditPreference;
