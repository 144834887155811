import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InfoIcon from "@mui/icons-material/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { editAdmin, getAppCustomerList, getOnBoardCustomerList, suspendActiveAdmin } from "../../api";
import AddEditAppCustomer from "../../components/app-user/AddEditAppCustomer";
import CustomerInfo from "../../components/app-user/CustomerInfo";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import { convertDateToMMDDYYYY, formatPhoneNumber } from "../../helpers/commonFunctions";
import SettingsIcon from '@mui/icons-material/Settings';
// import "./Role.css";
import CancelIcon from '@mui/icons-material/Cancel';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddEditAdmin from "../../components/app-user/AddEditAdmin";
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import MakeAdmin from "./MakeAdmin";
import OnBoardCustomerInfo from "./OnBoardCustomerInfo";

const OnBoardCustomer: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [superUserModal, setShowSuperUserModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showRemoveSuperUserPopup, setShowRemoveSuperUserPopup] = useState(false);
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
    roleId: 2,
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  const tableRef = useRef();
  const navigate = useNavigate();
  const {
    mutateAsync: getOnBoardCustomer,
    data: onBoardCustomerListData,
    isLoading: isGettingOnBoardCustomerData,
  } = useMutation(getOnBoardCustomerList);
  const { mutateAsync: suspendActive, isLoading: isSuspending } = useMutation(
    "API",
    suspendActiveAdmin,
  );

  const { mutateAsync: editAPI, isLoading: isEditing } = useMutation(
    "add-edit",
    editAdmin
  );

  useEffect(() => {
    getCustomer(tableOptions);
  }, []);

  useEffect(() => {
    getCustomer({
      ...tableOptions,
      search: debounceSearchValue ?? "",
    });
  }, [debounceSearchValue]);

  const getCustomer = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined,
  ) => {
    let response = await getOnBoardCustomer(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleAction = (actionType: string) => {
    switch (actionType) {
      case "Info":
        setShowDetailsPopup(true);
        break;
      case "userActiveSuspend":
        setShowActiveDeActivePopup(true);
        break;
      case "editCustomer":
        setShowAddEditModal(true);
        break;
      case "makeSuperUser":
        setShowSuperUserModal(true);
        break;
        case "removeSuperUser":
          setShowRemoveSuperUserPopup(true)
          break;
      default:
        break;
    }
    handleMenuClose();
  };

  const columns: GridColDef[] = [
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 1.2,
      sortable: false,
      renderCell: (data: any) =>
        <Tooltip
          title={data?.row?.businessName ? <h6 style={{ color: "white" }}>{data?.row?.businessName}</h6> : "-"}
          arrow
        >
          <span>{data?.row?.businessName ? charValidate(data?.row?.businessName, 18) : "-"}</span>
        </Tooltip>
    },
    {
      field: "website",
      headerName: "Website",
      flex: 1,
      sortable: false,
      renderCell: (data: any) => (
        <Tooltip
          title={data?.row?.website ? <h6 style={{ color: "white" }}>{data?.row?.website}</h6> : "-"}
          arrow
        >
          <span>{data?.row?.website ? <a href={data?.row?.website} target="_blank" rel="noopener noreferrer">{charValidate(data?.row?.website, 18)}</a> : "-"}</span>
        </Tooltip>
      ),
    },
    {
      field: "fullName",
      headerName: "Name",
      flex: 1.1,
      sortable: false,
      renderCell: (data: any) =>
        <Tooltip title={data?.row?.fullName && data?.row?.lastName ? <h6 style={{ color: "white" }}>{data?.row?.fullName} {data?.row?.lastName}</h6> : "-"} arrow>
          <div className="d-flex align-items-center justify-content-center">
            <span style={{ marginRight: "5px" }}>
              {data?.row?.fullName && data?.row?.lastName
                ? `${data?.row?.fullName} ${data?.row?.lastName}`
                : "-"}
            </span>
          </div>
        </Tooltip>
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      sortable: false,
      renderCell: (data: any) => (
        <Tooltip
          title={data?.row?.email ? <h6 style={{ color: "white" }}>{data?.row?.email}</h6> : "-"}
          arrow
        >
          <span>
            {data?.row?.email ? (
              <a href={`mailto:${data?.row?.email}`}>
                {charValidate(data?.row?.email, 15)}
              </a>
            ) : (
              "-"
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1.2,
      sortable: false,
      renderCell: (data: any) => (
        <Tooltip
          title={data?.row?.mobileNumber ? <h6 style={{ color: "white" }}>{formatPhoneNumber(data?.row?.countryCode, data?.row?.mobileNumber)}</h6> : "-"}
          arrow
        >
          <span>
            {data?.row?.mobileNumber ? (
               <a href={`tel:${data?.row?.mobileNumber}`}>
               {formatPhoneNumber(data?.row?.countryCode, data?.row?.mobileNumber)}
             </a>
            ) : (
              "-"
            )}
          </span>
        </Tooltip>
      ),
    },
    {
        field: "isEmailVerified",
        headerName: "Email",
        flex: 0.7,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (data: any) =>
          data?.row?.IsEmailVerified ? (
            <CheckCircleIcon sx={{ fontSize: 20, color: "green" }} />
          ) : (
            <CancelIcon sx={{ fontSize: 20, color: "red" }} />
          ),
      },
      {
        field: "isSMSVerified",
        headerName: "SMS",
        flex: 0.7,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (data: any) =>
          data?.row?.IsSMSVerified ? (
            <CheckCircleIcon sx={{ fontSize: 20, color: "green" }} />
          ) : (
            <CancelIcon sx={{ fontSize: 20, color: "red" }} />
          ),
      },
      {
        field: "isTermsAndConditionsVerified",
        headerName: "Terms",
        flex: 0.7,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (data: any) =>
          data?.row?.IsTermsAndConditionsVerified ? (
            <CheckCircleIcon sx={{ fontSize: 20, color: "green" }} />
          ) : (
            <CancelIcon sx={{ fontSize: 20, color: "red" }} />
          ),
      },
    {
      field: "join date",
      headerName: "Join Date",
      flex: 0.8,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => convertDateToMMDDYYYY(data?.row?.createdAt) ?? "-",
    },
    {
      field: "sector",
      headerName: "Sector",
      flex: 0.8,
      sortable: false,
      renderCell: (data: any) => (
        // <div>
        //   {data?.row?.industry && data?.row?.industry.length > 0
        //     ? data?.row?.industry.join(", ")
        //     : "-"}
        // </div>
        <Tooltip
          title={data?.row?.industry ? <h6 style={{ color: "white" }}>{data?.row?.industry.join(", ")}</h6> : "-"}
          arrow
        >
          <span>
            {data?.row?.industry ? (
               <div>
          {data?.row?.industry && data?.row?.industry.length > 0
            ? data?.row?.industry.join(", ")
            : "-"}
        </div>
            ) : (
              "-"
            )}
          </span>
        </Tooltip>
      ),
    },
    // {
    //   field: "isUserActive",
    //   headerName: "Status",
    //   flex: 1,
    //   sortable: false,
    //   headerAlign:'center',
    //   align:'center',
    //   renderCell: (data: any) =>
    //     typeof data?.row?.admin?.isUserActive !== "undefined" ? (
    //       data?.row?.admin?.isUserActive === 1 ? (
    //         <div className="d-flex align-items-center">
    //           <CheckCircleIcon sx={{ fontSize: 15, mr: 1, color: "green" }} />
    //           <span>Activate</span>
    //         </div>
    //       ) : (
    //         <div className="d-flex align-items-center">
    //           <CheckCircleIcon sx={{ fontSize: 15, mr: 1 }} color="disabled" />
    //           <span>Suspended</span>
    //         </div>
    //       )
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="More Actions">
            <IconButton
              onClick={(e) => handleMenuClick(e, data.row)}
              size="small"
              color="primary"
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            className="mr-6"
          >
            <MenuItem onClick={() => handleAction("Info")}>
              <InfoIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowDetailsPopup(true);
                }}
                sx={{ fontSize: 22, mr: 1.2, cursor: "pointer" }}
                color="primary"
              />
              Info
            </MenuItem>
  
            {selectedRow?.admin?._id && (
                <MenuItem onClick={() => handleAction("userActiveSuspend")}>
              <ManageAccountsIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowActiveDeActivePopup(true);
                }}
                sx={{ fontSize: 22, mr: 1.2, cursor: "pointer" }}
                color="primary"
              />
              {selectedRow?.admin?.isUserActive === 1 ? "Suspend" : "Activate"}
            </MenuItem>
            )}
  
           {selectedRow?.IsTermsAndConditionsVerified && selectedRow?.IsSMSVerified && selectedRow?.IsEmailVerified && (
            <MenuItem onClick={() => handleAction("editCustomer")}>
              <DriveFileRenameOutlineIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowAddEditModal(true);
                }}
                sx={{ fontSize: 22, mr: 1.2, cursor: "pointer" }}
                color="primary"
              />
              {selectedRow?.admin?._id ? "Edit" : "Make an admin"}
            </MenuItem>
           )}
          </Menu>
        </Box>
        )
    },
  ];
  

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        roleId: 2,
        search: debounceSearchValue ?? "",
      };
      getCustomer(query);
    }
  };

  const handleSuperUserModalClose = (isSubmit: boolean) => {
    setShowSuperUserModal(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        roleId: 2,
        search: debounceSearchValue ?? "",
      };
      getCustomer(query);
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      roleId: 2,
      search: debounceSearchValue ?? "",
    };
    getCustomer(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleCloseActivePopup = (isSubmit: boolean) => {
    setShowActiveDeActivePopup(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        roleId: 2,
        search: debounceSearchValue ?? "",
      };
      getCustomer(query);
    }
  };

  const activeDeactive = async () => {
    let bodyParams = {
      userId: selectedRow?._id ?? "",
      flag: selectedRow?.isUserActive === 1 ? true : false,
    };

    let response = await suspendActive(bodyParams);
    if (response?.status) {
      snackActions.success(response?.message ?? "Success!");
      handleCloseActivePopup(true);
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  const handleSuperUserPopUpClose = (isSubmit: boolean) => {
    setShowRemoveSuperUserPopup(false);
     setSelectedRow("");
     if (isSubmit) {
       let query = {
         offset: tableOptions?.offset ?? 0,
         limit: tableOptions?.limit ?? 10,
         roleId: 2,
         search: debounceSearchValue ?? "",
       };
       getCustomer(query);
     }
   };

  const handleCloseSuperUserPopup = () => {
    setShowRemoveSuperUserPopup(false);
  };

  const handleRemoveSuperUser = async() => {
    const filteredRoleValue = selectedRow?.role?.filter((id: any) => id !== 'superUser');
    const roleValue = filteredRoleValue?.length == 0 ? ['user'] : filteredRoleValue
      let removeSuperUser = {
            userId: selectedRow ? selectedRow?._id : undefined,
            firstName: selectedRow?.firstName ?? "",
            lastName: selectedRow?.lastName ?? "",
            mobileNumber: selectedRow?.mobileNumber ?? "",
            countryCode:
            selectedRow?.countryCode ?? "",
            email: selectedRow?.email ?? "",
            // type: (isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") ? "superUser" : "admin",
            // role: ["superAdmin"],
           role: roleValue,
            superUserHotels: [],
            superUserWellness: [],
            superUserRestaurants: [],
            superUserGolfs: []
          };
          let response = await editAPI(removeSuperUser);
          if (response?.status) {
            snackActions.success(
              response?.message ?? "Added/Edited Successfully!"
            );
            handleSuperUserPopUpClose(true)
          } else {
            snackActions.error(response?.message ?? "Something went wrong!");
          }
  }

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            addLabel="Add Customer"
            handleAddClick={() => setShowAddEditModal(true)}
            columns={columns}
            data={onBoardCustomerListData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isGettingOnBoardCustomerData}
            isRowClickable={true}
            isAddEnable={false}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Box pb={3} display="flex" alignItems="center">
                <Box className="table-search">
                  <label
                    className="form-label fw-bold"
                    style={{ marginLeft: 5 }}
                  >
                    Search By Name
                  </label>
                  <Box className="with-search-ico">
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        backgroundColor: "#E0DEE7",
                        border: "0px solid #ccc",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      placeholder="Search by name"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                    {tableOptions?.search && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleChangeFilters('search', '')}
                        ></i>
                      )}
                  </Box>
                </Box>
              </Box>
            }
          />
        </KTCard>
        {showDetailsPopup && (
          <OnBoardCustomerInfo
            details={selectedRow}
            show={showDetailsPopup}
            handleClose={() => {setShowDetailsPopup(false); setSelectedRow('')}}
          />
        )}
        {showAddEditModal && (
          <MakeAdmin
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showActiveDeActivePopup && (
          <ConfirmEventPopup
            showPopup={showActiveDeActivePopup}
            handleClose={handleCloseActivePopup}
            handleConfirmed={activeDeactive}
            loading={isSuspending}
            title={`${selectedRow?.admin?.isUserActive == 1 ? "Suspend": "Activate"} Business Inquiry`}
            message={`Are you sure, you want to ${
              selectedRow?.admin?.isUserActive === 1 ? "Suspend" : "Activate"
            } this Business Inquiry?`}
          />
        )}
      </>
    </Card>
  );
};

export default OnBoardCustomer;
