import { Box } from '@mui/material';
import Card from 'react-bootstrap/Card';

interface CategoryCardViewProps {
  category: any,
    onClick : any
    handleAddCategoryClick : any
    handleDeleteCategoryClick : any
  }

const CategoryCardView : React.FC<CategoryCardViewProps> = ({category, onClick, handleAddCategoryClick ,handleDeleteCategoryClick }) => {

  // const handleAddEditCategoryClick = () => {
  //   if (handleAddCategoryClick) {
  //     handleAddCategoryClick(); 
  //   }
  // };

  // const handleDeleteClick = (value : any) => {
  //   if (handleDeleteCategoryClick) {
  //     handleDeleteCategoryClick(value); 
  //   }
  // };

  return (
    <Box pb={1}>
    <Card
        className="cursor-pointer category-card"
        style={{ backgroundColor:'#f0f0f0', height: '100px', marginBottom: '20px', position: 'relative' }}
        onClick={() => onClick(category._id)}
    >
        <Card.Body style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <div style={{ flex: '1', overflow: 'hidden' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Card.Title>{category.name}</Card.Title>
                    <div style={{ width: '100%', overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                        <Card.Text>{category.description}</Card.Text>
                    </div>
                </div>
            </div>
        </Card.Body>
    </Card>
</Box>
  );
};

export default CategoryCardView;
