import { FC, useEffect } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTIcon, WithChildren } from '../../../../helpers'
import { useLayout } from '../../../core'
import { useDispatch } from 'react-redux'
import { setData } from '../../../../../store/slice/header/headerSlice'
import { isSuperAdmin } from '../../../../../hooks/useUserInfo'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { app } = config
  
  const dispatch = useDispatch()
  
  // Function to extract the last segment of the URL path and format it
  const formatPathSegment = (pathname: string) => {
    const segments = pathname.split('/').filter(Boolean)
    const lastSegment = segments[segments.length - 1] || ''

    // Custom formatting for specific route
    if (lastSegment === 'category_hotel_list') {
      return 'Hotels'
    }
    if (lastSegment === 'wellness_list') {
      return 'Wellness List'
    }
    if (lastSegment === 'category_restaurant_list') {
      return 'Restaurants'
    }
    if (lastSegment === 'category-restaurant-details') {
      return 'Restaurant Details'
    }
    if (lastSegment === 'category-token-info') {
      return 'Token Info'
    }
    if (lastSegment === 'category-hotel-details') {
      return 'Hotel Details'
    }
    if (lastSegment === 'category_wellness_list') {
      return 'Wellness'
    }
    if (lastSegment === 'category-wellness-details') {
      return 'Wellness Details'
    }
    if (lastSegment === 'category-golf-club') {
      return 'Golf'
    }
    if (lastSegment === 'category-golf-details') {
      return 'Golf Details'
    }
    if (lastSegment === 'category-golf-holes') {
      return 'Golf Holes'
    }
    if (lastSegment === 'category-golf-offers') {
      return 'Golf Offers'
    }
    if (lastSegment === 'waitlist') {
      return 'WaitList'
    }
    if(lastSegment === 'concierge-user'){
      return 'Concierge User'
    }
    if (lastSegment === 'userPreference') {
      return 'User Preference'
    }
    if (lastSegment === 'userPreference_details') {
      return 'User Preference Details'
    }
    if (lastSegment === 'web-contactus') {
      return 'Contact Us Web'
    }
    if (lastSegment === 'contactus') {
      return 'Contact Us Mobile'
    }
    if (lastSegment === "faq"){
      return "FAQ"
    }
    
    // Default formatting: Replace hyphens with spaces and capitalize the first letter of each word
    return lastSegment
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const formattedPathSegment = formatPathSegment(pathname)
  localStorage.setItem("title", formattedPathSegment)
  localStorage.setItem("pathName", pathname)
  // Format the path segment on component load
  useEffect(() => {
    const formattedPathSegment = formatPathSegment(pathname)
    localStorage.setItem("title", formattedPathSegment)
  }, [pathname])

  const handleMenuClick = async () => {
    dispatch(setData(title));
    const formattedPathSegment = formatPathSegment(pathname)
    localStorage.setItem("title", formattedPathSegment)
  }

  return (
    (!(isSuperAdmin && to === "/concierge-user" && hasBullet == false) ? 
      <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', { active: isActive })}
        to={to}
        onClick={() => handleMenuClick()}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
    : null)
  )
}

export { SidebarMenuItem }
