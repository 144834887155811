import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation } from 'react-router-dom';
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import { PageTitle } from "../../_metronic/layout/core";
import {
  deleteApparel,
  getApparelList,
} from "../../api";
import AddEditApparel from "../../components/apparel/AddEditApparel";
import MuiTable from "../../components/table/MuiTable";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";


  const ApparelDetails: React.FC = () => {
  const location: any = useLocation();
  const info = location?.state;
  const tableRef = useRef();
  const [tableOptions, setTableOptions] = useState({
    type: info.catKey,
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  useEffect(() => {
    getApparel(tableOptions);
    setSelectedRow({"type" : info.catKey})
  }, []);

  const getApparel = async (
    query:
      | {
          type: string;
        }
      | undefined,
  ) => {
    let response = await getApparelData(query);
    if (response?.status) {}
  };

  const {
    mutateAsync: getApparelData,
    data: apparelData,
    isLoading: isGettingPreferenceData,
  } = useMutation("preference-list", getApparelList);

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow({"type" : info.catKey})
    if (isSubmit) {
      let query = {
        type: tableOptions?.type ?? "",
      };
      getApparel(query);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow({"type" : info.catKey});
  };

  const { mutateAsync: deleteApparelData, isLoading: isDeleting } = useMutation(
    "delete",
    deleteApparel,
  );

  const handleDeleteConfirm = async () => {
    let response = await deleteApparelData({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      let query = {
        type: tableOptions?.type ?? "",
      };
      getApparel(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "CategoryType",
      headerName: "Category Type",
      flex: 1.2,
      sortable: false,
      renderCell: (data) => (
        <span>
        {data?.row?.catType ?? "-"}
      </span>
      ),
    },
    {
      field: "Value",
      headerName: "Value",
      flex: 1.2,
      sortable: false,
      renderCell: (data) => (
        <span>
        {data?.row?.value ?? "-"}
      </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color= "error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Card className="p-6">
         <PageTitle>{info.title}</PageTitle>
         <>
         <KTCard>
          <MuiTable
            addLabel='Add'
            isAddEnable={true}
            columns={columns}
            data= {apparelData?.data ?? []}
            tableRef={tableRef}
            loading={isGettingPreferenceData}
            handleAddClick={() => setShowAddEditModal(true)}/>
        </KTCard>
        {showAddEditModal && (
          <AddEditApparel
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={handleDeleteConfirm}
            loading={isDeleting}
            title={"Delete"}
            message={"Are you sure, you want to delete?"}
          />
        )}
        </>
    </Card>
  );
};

export default ApparelDetails;