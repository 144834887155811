import { useNavigate } from "react-router-dom";
import CategoryCardView from "./CatergoryCardView";
import "../../pages/preference/Preference.css";
import { useMutation, useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import { deleteCategory, getAdminCategories, getCategories } from "../../api";
import { ListGroup } from "react-bootstrap";
import AddEditCategory from "./AddEditCategory";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { setData } from "../../store/slice/header/headerSlice";
import { useDispatch } from "react-redux";
import { isAdmin, isSuperAdmin } from "../../hooks/useUserInfo";

const Category: React.FC = () => {
  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    mutateAsync: refetchCategories,
  } = useMutation("category", getCategories);

  const {
    data: adminCategoryData,
    isLoading: isLoadingAdminCategory,
    error: adminCategoryAPIError,
    mutateAsync: refetchAdminCategories,
  } = useMutation("admin-category", getAdminCategories);

  useEffect(() => {
    if (isAdmin) {
      refetchAdminCategories();
    } else {
      refetchCategories();
    }
  }, []);


  const [selectedRow, setSelectedRow] = useState<any>();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigationOnCategoryClick = (value: any) => {
    if (value === process.env.REACT_APP_ID_REST_CATE) {
      navigate("/category_restaurant_list", { state: value });
      dispatch(setData("Restaurant List"));
    } else if (value === process.env.REACT_APP_ID_GOLF_CATE) {
      navigate("/category-golf-club", { state: value });
      dispatch(setData("Golf Club"));
    } else if (value === process.env.REACT_APP_ID_HOTEL_CATE) {
      navigate("/category_hotel_list", { state: value });
      dispatch(setData("Hotel List"));
    } else if (value === process.env.REACT_APP_ID_HEALTH_CARE_CATE) {
      navigate("/health_care_list", { state: value });
      dispatch(setData("Health Care List"));
    } else if (value === process.env.REACT_APP_ID_SALOON_CATE) {
      dispatch(setData("Wellness List"));
      navigate("/category_wellness_list", { state: value });
    }
  };

  const { mutateAsync: deleteListItem, isLoading: isDeleting } = useMutation(
    "delete",
    deleteCategory
  );

  const confirmDelete = async () => {
    let response = await deleteListItem({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      setShowDeleteConfirmModal(false);
      setSelectedRow("");
      if(isSuperAdmin){
        refetchCategories();
      }
      else if(isAdmin){
        refetchAdminCategories()
      }
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleAddCategoryClick = (value: any) => {
    setShowAddEditModal(true);
    setSelectedRow(value);
  };

  const handleDeleteCategoryClick = (value: any) => {
    setShowDeleteConfirmModal(true);
    setSelectedRow(value);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      if(isSuperAdmin){
        refetchCategories();
      }
      else if(isAdmin){
        refetchAdminCategories()
      }
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  return (
    <div>
      <ListGroup>
        <ListGroup.Item
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="grid-container">
            {(isAdmin ? adminCategoryData?.data?.docs : categoryData?.data?.docs)
              // ?.filter((category: any) =>
              //   category._id !== process.env.REACT_APP_ID_HOTEL_CATE &&
              //   category._id !== process.env.REACT_APP_ID_HEALTH_CARE_CATE
              // )
              ?.map((category: any) => (
                <CategoryCardView
                  key={category?.id}
                  category={category}
                  handleAddCategoryClick={() =>
                    handleAddCategoryClick(category)
                  }
                  handleDeleteCategoryClick={() =>
                    handleDeleteCategoryClick(category)
                  }
                  onClick={handleNavigationOnCategoryClick}
                />
              ))}
          </div>
        </ListGroup.Item>
      </ListGroup>
      {showAddEditModal && (
        <AddEditCategory
          editData={selectedRow}
          show={showAddEditModal}
          handleClose={handleModalClose}
        />
      )}
      {showDeleteConfirmModal && (
        <ConfirmEventPopup
          showPopup={showDeleteConfirmModal}
          handleClose={handleCloseDelete}
          handleConfirmed={confirmDelete}
          loading={isDeleting}
          title={"Delete Category"}
          message={"Are you sure, you want to delete this Category?"}
        />
      )}
    </div>
  );
};

export default Category;