const BASE_URL = process.env.REACT_APP_BASE_URL;

export const userToken = localStorage.getItem("userToken");

// configure header's Content-Type as JSON
export const config = {
  headers: {
    ContentType: "application/json",
    accept: "application/json",
    Authorization: `Bearer ${userToken}`,
  },
};

export const APP_URLs = {
    refreshToken: {
      refresh: `${BASE_URL}/user/refreshToken`,
    },
  mediaUpload: `${BASE_URL}/media/uploadpicture`,
  authEndPoints: {
    login: `${BASE_URL}/admin/signin`,
    logout: `${BASE_URL}/admin/logout`,
  },
  golfEndPoints: {
    getAllGolf: `${BASE_URL}/golf/all`,
    golfCommonAPI: `${BASE_URL}/golf`,
    activeDeactiveGolf: `${BASE_URL}/golf/active`,
    //holes:
    addEditGolfHoles: `${BASE_URL}/golf/hole`,
    // third-party-api
    addEditThirdPartyAPI: `${BASE_URL}/golf/api`,
  },
  termsAndCondition: {
    getTerms: `${BASE_URL}/static/terms`,
    addTerms: `${BASE_URL}/static/terms`,
  },
  privacyAndPolicy: {
    getPrivacy: `${BASE_URL}/static/privacy`,
    addPrivacy: `${BASE_URL}/static/privacy`,
  },
  paymentTerms: {
    getPaymentTerms: `${BASE_URL}/static/payment-terms`,
    addPaymentTerms: `${BASE_URL}/static/payment-terms`,
  },
  paymentPrivacy: {
    getPaymentPrivacy: `${BASE_URL}/static/payment-privacy`,
    addPaymentPrivacy: `${BASE_URL}/static/payment-privacy`,
  },
  aboutUs: {
    getAboutUs: `${BASE_URL}/static/about-us`,
    addAboutUs: `${BASE_URL}/static/about-us`,
  },
  changeUserPassword: {
    genericAPI: `${BASE_URL}/user/change-password`,
  },
  subscription: {
    SubscriptionCommonAPI: `${BASE_URL}/subscription`,
  },
  commonEndPoints: {
    getCountries: `${BASE_URL}/auth/getCountries`,
    getStates: `${BASE_URL}/auth/getStates`,
    getCities: `${BASE_URL}/auth/getCities`,
  },
  faq: {
    genericFAQAPI: `${BASE_URL}/faq`,
  },
  appUser: {
    appCustomerList: `${BASE_URL}/admin/userList`,
    addAdmin: `${BASE_URL}/admin/create-admin`,
    onBoardCustomerList: `${BASE_URL}/OnBoardCustomer/getCustomerDetails`,
    editAdmin: `${BASE_URL}/admin/edit-details`,
    suspendAdmin: `${BASE_URL}/admin/suspend-user`,
  },
  offers: {
    offersCommon: `${BASE_URL}/offer`,
  },
  banners: {
    bannerComnonApi: `${BASE_URL}/banner`,
  },
  company: {
    genericCompanyAPI: `${BASE_URL}/company`,
  },
  booking: {
    genericSlotBookingAPI: `${BASE_URL}/booking`,
  },
  subscriptionInfo: {
    genericSubscriptionInfoAPI: `${BASE_URL}/subscriptionInfo`,
  },
  coupon: {
    genericCouponAPI: `${BASE_URL}/coupon`,
  },
  contactus: {
    getContactusList: `${BASE_URL}/contact-us`,
    getContactusWebList: `${BASE_URL}/contact-us/getWebContactUs`,
  },
  inquiry: {
    getInquiryList: `${BASE_URL}/contact-us/getInquries`,
  },
  membershipDescription: {
    genericMembershipDescription: `${BASE_URL}/static/membership-description`,
  },
  squezzMe: {
    getSquezzMeList: `${BASE_URL}/slotRequest`,
    updateRequestStatusForAll : `${BASE_URL}/slotRequest/updateStatus`,
    slotRestrictRequest : `${BASE_URL}/slotRequest/restrictSqueezRequest`,
    getSlotRestrictDetails: `${BASE_URL}/slotRequest/getSqueezeRestrictions`,
    deleteSlot: `${BASE_URL}/slotRequest/deleteRestrictSqueezRequests`
  },
  restaurant:{
getRestaurantDetails: `${BASE_URL}/restaurant`,
widgetDetails: `${BASE_URL}/restaurant/addWidgetDetails`,
getWidgetDetails: `${BASE_URL}/restaurant/getWidgetDetails`,
deleteWidget: `${BASE_URL}/restaurant/deleteRestaurantWidgetDetail`
  },
  interest:{
    genericInterestAPI : `${BASE_URL}/interest/restaurant`,
    genericInterestAPIforGolf : `${BASE_URL}/interest/golf`,
  },
  apparel:{
    genericApparelAPI : `${BASE_URL}/apparel`,
  },
  category:{
    genericCategoryAPI : `${BASE_URL}/category`,
    genericCategoryRestaurant : `${BASE_URL}/restaurant`,
    genericCategoryRestaurantforAdmin : `${BASE_URL}/restaurant/adminRestuarantDetails`,
    RestaurantCommonAPI: `${BASE_URL}/restaurant`,
    providerCommonAPI: `${BASE_URL}/restaurant/api`,
    activeInActiveRestaurant: `${BASE_URL}/restaurant/activeInactive-restaurant`,
    genericAddCategoryAPI: `${BASE_URL}/category`,
    genericDeleteCategoryAPI: `${BASE_URL}/category`,
    getAllItemDetailsAPI : `${BASE_URL}/category/get-AllItemDetails`,
    genericAdminCategoryAPI : `${BASE_URL}/category/adminCategory`
  },
  notify:{
    generiWaitingListAPI : `${BASE_URL}/notify`,
    genericWaitingUserNotifyAPI : `${BASE_URL}/notify/notifyUser`,
  },
  wellness : {
    genericWellnessAPI : `${BASE_URL}/wellness`,
    genericWellnessAPIForAdmin : `${BASE_URL}/wellness/getAdminWellnessDetails`,
    activeDeactiveWellness : `${BASE_URL}/wellness/activeInactive-wellness`
  },
  health : {
    genericHealthAPI : `${BASE_URL}/healthCare`,
  },
  hotel : {
    genericHotelAPI : `${BASE_URL}/hotel`,
    genericHotelforAdmin : `${BASE_URL}/hotel/getAdminHotelDetails`,
    activeInActiveHotel: `${BASE_URL}/hotel/activeInactive-hotel`,
  },
  dashboard: {
    getDashboard: `${BASE_URL}/dashboard/analytics`,
  },
  check_domain:{
    domain_avaliable :`${BASE_URL}/category/check-domain`
  },
  payment_provider:{
provider:`${BASE_URL}/provider`,
generateStripeLink:`${BASE_URL}/stripe/generateLink`,
  }
};
