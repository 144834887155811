import { Button, Modal } from "react-bootstrap";
import { KTIcon } from "../../../helpers";

const ConfirmEventPopup = ({
  showPopup,
  handleClose,
  handleConfirmed,
  title,
  message,
  loading = false,
}: {
  showPopup: boolean;
  handleClose: (isSubmit: boolean) => void;
  handleConfirmed: (any: string) => void;
  title: string;
  message: string;
  loading: boolean;
}) => {
  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      show={showPopup}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{title}</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="p-5">
          <h5>{message}</h5>
        </div>
        <div className="w-100 d-flex p-5 align-items-center  justify-content-center">
          <Button
            disabled={loading}
            onClick={() => handleClose(false)}
            size="lg"
            variant="secondary"
          >
            Cancel
          </Button>
          <div className="w-15px"></div>
          <Button
            disabled={loading}
            onClick={handleConfirmed.bind(null,'')}
            size="lg"
            variant="primary"
          >
            {loading ? "Loading..." : "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmEventPopup;
