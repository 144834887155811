/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { ContactUs } from "../../components/auth/ContactUs";
import { ForgotPassword } from "../../components/auth/ForgotPassword";
import { Login } from "../../components/auth/Login";
import { Registration } from "../../components/auth/Registration";
import { red } from "@mui/material/colors";

const AuthLayout = () => {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div className="h-100 position-relative d-flex align-items-center justify-content-center"> 
      <div className="login-bg">
        <img src="../media/auth/bg-auth.jpg" alt="Login BG" />
      </div> 
      <div className="container d-flex w-100 flex-column">
        <div className="row align-items-center justify-content-lg-end my-auto py-5">      
          <div className="align-items-center col-xl-6 col-lg-7 d-flex h-100">
            <div className="login-form w-100 p-10 p-sm-15 my-5">
              <Outlet />
            </div>          
          </div>            
        </div>
      </div>      
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="contactus" element={<ContactUs />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
