import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import CancelIcon from '@mui/icons-material/Cancel';
import { KTIcon } from "../../_metronic/helpers";
import {
  addRestaurant,
  getCities,
  getCountries,
  getStates,
  uploadPicture,
  getCategories,
  addHotel,
} from "../../api";
import { IRestaurant } from "../../constants/types";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { charValidate } from "../../components/utility";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
import httpsClient from "../../api/httpsClient";
import { APP_URLs } from "../../constants/appURLs";
import Select from 'react-select';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { multiSectionDigitalClockSectionClasses, TimePicker } from "@mui/x-date-pickers";
import { convertDayjsFormatTimeTo24HourFormat, convertTo24HourFormat, convertToDayjsFormat } from "../../helpers/commonFunctions";
import './AddEditHotel.css'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
  Box,
  Chip,
  Stack,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: IRestaurant;
};

interface IHoursOfOperation {
  name: string;
  startTime: string;
  endTime: string;
}

interface IBusiestDay {
  startTime: string;
  endTime: string;
}

interface IHoursOfOperation {
  name: string;
  startTime: string;
  endTime: string;
}

interface IBusiestDay {
  startTime: string;
  endTime: string;
}

interface IDailySchedule {
  day: string;
  hoursOfOperationOne: IHoursOfOperation;
  busiestDayOne: IBusiestDay;
  IsOpen: boolean;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditHotel = ({ show, handleClose, editData }: Props) => {
  let imgUrl: string = editData?.images
    ? editData?.images[0]
      ? editData?.images[0]
      : ""
    : "";
  const [resaturantImg, setGolfImg] = useState<string>("");
  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );

  const [isDomainAvailable, setIsDomainAvailable] = useState<boolean>(true);
  const [isCheckingDomain, setIsCheckingDomain] = useState<boolean>(false);
  const [isDomainChecked, setIsDomainChecked] = useState<boolean>(false);
  const [originalDomain, setOriginalDomain] = useState<string>(editData?.domain || "");
  const [selectedCountry, setSelectedCountry] = useState<any>()
  const [selectedState, setSelectedState] = useState<any>()
  const [selectedCity, setSelectedCity] = useState<any>()
 
  const initialValueForDayCount = [
    {
      day: "Monday",
      counts: ''
    },
    {
      day: "Tuesday",
      counts: ''
    },
    {
      day: "Wednesday",
      counts: ''
    },
    {
      day: "Thursday",
      counts: ''
    },
    {
      day: "Friday",
      counts: ''
    },
    {
      day: "Saturday",
      counts: ''
    },
    {
      day: "Sunday",
      counts: ''
    }
  ]

  const [dayCount, setDayCount] = useState<any>(initialValueForDayCount)
  const [logoFileName, setLogoFileName] = useState<string>(
    editData?.marketingWebLogo || ""
  );
  const [websiteFileName, setWebsiteFileName] = useState<string>(
    editData?.marketingWebImage || ""
  );
  const [selectedDay, setSelectedDay] = useState<string>("Monday");
  const [isChecked, setIsChecked] = useState(false)
  const initialValueForSchedule = [
    {
        "day": "Monday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Tuesday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Wednesday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Thursday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Friday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Saturday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    },
    {
        "day": "Sunday",
        "hoursOfOperationOne": {
          "name": "",
          "startTime": "",
          "endTime": "",
        },
        "busiestDayOne": {
          "startTime": "",
          "endTime": "",
        },
        "IsOpen": false
    }
]
  const [schedule, setSchedule] = useState<IDailySchedule[]>(initialValueForSchedule);

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().required("Description is required"),
    minThreshold: Yup.number().required("ThreshHold amount is required").min(1, "Minimum Threshold Amount should be greater than zero"),
    minSqueezAmount: Yup.number().required('Auto approve amount is required').min(1, "Auto - Approved Amount should be greater than zero")
        .test('autoapprove-amount', 'Auto approve amount should be greater than minimum thresold amount', function (value) {
          if (!value) return false;
          const minThresold = formik.values.minThreshold;
    
          if(value <= minThresold){
            return false;
          }
    
          return true
        }),
        
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    addressName: Yup.string().trim().required("Address is required"),
    personCount: Yup.number().required("Person count is required").min(1, "Person count should be greater than zero"),
    websiteURL: Yup.string().trim().required("Booking URL is required"),
    maximumPrice: Yup.number().required("Maximum price is required").min(1, "Maximum price should be greater than zero")
      .test('maximum-price', 'Maximum price should be greater than or equal to auto approve amount', function (value) {
        if (!value) return false;
        const autoApprovePrice = formik.values.minSqueezAmount;
        const isAutoApprove = formik.values.IsAutoApprove;

        if (value < autoApprovePrice && isAutoApprove) {
          return false;
        }

        return true
      }),
    street: Yup.string().trim().required("Street is required"),
    // unit: Yup.string().trim().required("Unit is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    country: Yup.string().trim().required("Country is required"),
    zip: Yup.string().trim().required("Zip is required"),
    mobileNumber: Yup.string()
          .required("Please enter mobile number")
          .test('mobile-number', 'Mobile number format is incorrect', function (value) {
            if (!value) return false;
            const cleanedNumber = value.replace(formik.values.countryCode, '').replace(/\D/g, '');
            if (cleanedNumber.length === 0) {
              return this.createError({ message: "Please enter mobile number" });
            }
            if (cleanedNumber.length < 10) {
              return false;
            }
            return true;
          }),
    isSqueezOpen: Yup.boolean()
      .test('at-least-one', 'At least one of Squeez or Waitlist must be selected', function (value) {
        const { isWaitListOpen } = this.parent;
        if (value || isWaitListOpen) {
          return true;
        }
        return false;
      }),
    isWaitListOpen: Yup.boolean()
      .test('at-least-one', 'At least one of Squeez or Waitlist must be selected', function (value) {
        const { isSqueezOpen } = this.parent;
        if (value || isSqueezOpen) {
          return true;
        }
        return false;
      })
    // reservationLink: Yup.string().trim(),
  });

  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    refetch: refetchCategories,
  } = useQuery("category", getCategories);

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    error: countryAPIError,
  } = useQuery("countries", getCountries);

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
    error: stateAPIError,
  } = useMutation("states", getStates);
  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation("cities", getCities);
  const {
    mutateAsync: addRestaurantCategory,
    isLoading: isAddingGolf,
    error: addGoldAPIError,
  } = useMutation("add-restaurant", addHotel);

  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture
  );

  const restaurant = categoryData?.data?.docs?.find(
    (s: any) => s?._id == process.env.REACT_APP_ID_HOTEL_CATE
  );

  const formik: any = useFormik({
    initialValues: {
      name: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      description: editData?.description ?? "",
      latitude: editData?.LocationLatLng?.coordinates[1] ?? "",
      longitude: editData?.LocationLatLng?.coordinates[0] ?? "",
      minThreshold: editData?.minThreshold ?? null,
      minSqueezAmount: editData?.minSqueezAmount ?? null,
      addressName: editData?.address?.name
        ? editData?.address?.name?.replace(/^\s+|\s+$/g, "")
        : "",
      street: editData?.address?.street
        ? editData?.address?.street?.replace(/^\s+|\s+$/g, "")
        : "",
      unit: editData?.address?.unit ?? "",
      city: editData?.address?.cityId ?? "",
      zip: editData?.address?.zip ?? "",
      state: editData?.address?.stateId ?? "",
      country: editData?.address?.countryId ?? "",
      websiteURL: editData?.website
        ? editData?.website?.replace(/^\s+|\s+$/g, "")
        : "",
      mobileNumber: editData?.mobileNumber ?? "",
      restaurantType: editData?.type,
      emailAddress: editData?.emailAddress,
      countryCode: editData?.countryCode ?? "",
      personCount: editData?.maxPersonCount || null,
      maximumPrice: editData?.priceRange?.maxPrice || null,
      tax: editData?.tax ?? 0,
      marketingWebLogo: editData?.marketingWebLogo,
      marketingWebImage: editData?.marketingWebImage,
      convenienceFee: editData?.convenienceFee ?? 0,
      cateId: restaurant?._id,
      menuUrl: editData?.menuUrl,
      instagramUrl: editData?.instagramUrl,
      facebookUrl: editData?.facebookUrl,
      twitterUrl: editData?.twitterUrl,
      isSqueezOpen: editData?.featureSqueezWaitlist?.isSqueezOn ?? true,
      isWaitListOpen: editData?.featureSqueezWaitlist?.isWaitlistOn ?? true,
      awardWinning: editData?.awardWinning,
      price: editData?.price,
      cuisine: editData?.cuisine,
      hoursOfOperation: editData?.hoursOfOperation,
      paymentOptions: editData?.paymentOptions,
      parking: editData?.parking,
      restaurantUrl: editData?.restaurantUrl,
      safety: editData?.safety,
      yelpReview: editData?.yelpReview,
      tripAdvisorReview: editData?.tripAdvisorReview,
      eaterReview: editData?.eaterReview,
      googleReview: editData?.googleReview,
      reservationLink: editData?.reservationLink,
      IsParkingOpen: editData?.IsParkingOpen ?? false,
      IsAutoApprove: editData?.IsAutoApprove ?? true,
      domain: editData?.domain,
      primaryColor: editData?.primaryColor,
      secondaryColor: editData?.secondaryColor,
      lightColor: editData?.lightColor,
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {

      const formattedMaximumPrice = {
        maxPrice: values?.maximumPrice
      }

      const isHoursOfOperationValid = schedule?.every((dayData) => {
        const isHoursOfOperationOneValid = (dayData?.hoursOfOperationOne?.name && dayData?.hoursOfOperationOne?.startTime && dayData?.hoursOfOperationOne?.endTime) ||
                                            (!dayData?.hoursOfOperationOne?.name && !dayData?.hoursOfOperationOne?.startTime && !dayData?.hoursOfOperationOne?.endTime);
        
        const isBusiestDayOneValid = (dayData?.busiestDayOne?.startTime && dayData?.busiestDayOne?.endTime) ||
                                     (!dayData?.busiestDayOne?.startTime && !dayData?.busiestDayOne?.endTime);
  
        let isValid;

        isValid = dayData?.IsOpen ? (isHoursOfOperationOneValid && isBusiestDayOneValid) : true
      
        return isValid;
      });

      const formattedBookingPreferences = {
        isSqueezOn: values?.isSqueezOpen,
        isWaitlistOn: values?.isWaitListOpen
      }

      const formattedDayCountValue = dayCount?.map((time: any) => {
        return {
          ...time,
          counts: time?.counts !== '' ? Number(time?.counts) : ''
        };
      });

      const formattedSchedule = schedule?.map((time: any) => {
        const isOpen = 
        !time?.hoursOfOperationOne?.startTime && 
        !time?.hoursOfOperationOne?.endTime &&
        !time?.busiestDayOne?.startTime &&
        !time?.busiestDayOne?.endTime
      
        return {
          ...time,
          IsOpen: isOpen ? false : time?.IsOpen,
      
          hoursOfOperationOne: {
            ...time?.hoursOfOperationOne,
            startTime: time?.hoursOfOperationOne?.startTime ? convertDayjsFormatTimeTo24HourFormat(time?.hoursOfOperationOne?.startTime?.$d) : '',
            endTime: time?.hoursOfOperationOne?.endTime ? convertDayjsFormatTimeTo24HourFormat(time?.hoursOfOperationOne?.endTime?.$d) : ''
          },
          busiestDayOne: {
            ...time?.busiestDayOne,
            startTime: time?.busiestDayOne?.startTime ? convertDayjsFormatTimeTo24HourFormat(time?.busiestDayOne?.startTime?.$d) : '',
            endTime: time?.busiestDayOne?.endTime ? convertDayjsFormatTimeTo24HourFormat(time?.busiestDayOne?.endTime?.$d) : ''
          },
        };
      });

      if (values?.domain?.trim() === "" || values?.domain?.trim() === undefined) {
        formik.setFieldError("domain", "Please enter domain name");
        return;
        }
      if (values.domain.trim() !== "" && values.domain !== originalDomain) {
        setIsCheckingDomain(true);
        const isAvailable = await checkDomainAvailability(values.domain, restaurant?._id);
        setIsCheckingDomain(false);
        setIsDomainAvailable(isAvailable);
        setIsDomainChecked(true);
      } else if (!isDomainAvailable && values.domain.trim() !== "") {
        formik.setFieldError("domain", "Domain already exists");
        return; 
      }
    
      if (isDomainAvailable && values.domain.trim() !== "") {
        formik.setFieldError("domain", "");
      }
    
      const country = countryData?.data?.find(
        (c: any) => c?.countryId == values?.country
      );
      const state = stateData?.data?.find(
        (s: any) => s?.stateId == values?.state
      );

      const city = cityData?.data?.find((c: any) => c?.cityId == values?.city);
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        cateId: restaurant?._id,
        name: values?.name ? values?.name?.replace(/^\s+|\s+$/g, "") : "",
        description: values?.description ?? "",
        minThreshold: values?.minThreshold ?? 0,
        minSqueezAmount: values?.minSqueezAmount ?? 0,
        address: {
          name: values?.addressName
            ? values?.addressName?.replace(/^\s+|\s+$/g, "")
            : "",
          street: values?.street
            ? values?.street?.replace(/^\s+|\s+$/g, "")
            : "",
          unit: "",
          cityId: values?.city ?? "",
          zip: values?.zip ?? "",
          stateId: values?.state ?? "",
          countryId: values?.country ?? "",
          country: country?.name ?? "",
          state: state?.name ?? "",
          city: city?.name ?? "",
        },
        images: resaturantImg ? [resaturantImg] : "",
        latitude: values?.latitude ?? "",
        longitude: values?.longitude ?? "",
        requestCounts: formattedDayCountValue,
        featureSqueezWaitlist: formattedBookingPreferences,
        type: values?.restaurantType,
        marketingWebImage: websiteFileName,
        maxPersonCount: values?.personCount,
        priceRange: formattedMaximumPrice,
        marketingWebLogo: logoFileName,
        emailAddress: values?.emailAddress,
        website: values?.websiteURL
          ? values?.websiteURL?.replace(/^\s+|\s+$/g, "")
          : "",
        menuUrl: values?.menuUrl
          ? values?.menuUrl?.replace(/^\s+|\s+$/g, "")
          : "",
        // mobileNumber: values?.mobileNumber
        // ? values?.mobileNumber
        // : "",
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode.length}`)
          : "",
        countryCode: values?.countryCode ?? "1",
        tax: values?.tax ?? 0,
        convenienceFee: values?.convenienceFee ?? 0,
        instagramUrl: values?.instagramUrl,
        facebookUrl: values?.facebookUrl,
        twitterUrl: values?.twitterUrl,
        awardWinning: values?.awardWinning,
        price: values?.price,
        cuisine: values?.cuisine,
        hoursOfOperation: values?.hoursOfOperation,
        hoursOfOperationList: formattedSchedule,
        paymentOptions: values?.paymentOptions,
        parking: values?.parking,
        restaurantUrl: values?.restaurantUrl,
        safety: values?.safety,
        yelpReview: values?.yelpReview,
        tripAdvisorReview: values?.tripAdvisorReview,
        IsParkingOpen: values?.IsParkingOpen,
        eaterReview: values?.eaterReview,
        googleReview: values?.googleReview,
        reservationLink: values?.reservationLink,
        IsAutoApprove: values?.IsAutoApprove ?? true,
        domain: values?.domain,
        primaryColor: values?.primaryColor,
        secondaryColor: values?.secondaryColor,
        lightColor: values?.lightColor,
      };

      if(isHoursOfOperationValid){
      let response = await addRestaurantCategory(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Hotel Added Successfully!"
        );
        formik.resetForm();
        setImgFileName("");
        setGolfImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    }
    },
  });
  const countryValue = formik.values.country;
  const stateValue = formik.values.state;

  useEffect(() => {
    if (editData) {
      if(editData?.requestCounts?.length){
        const formattedDayCount = editData?.requestCounts?.map((time: any) => {
          return{
            ...time,
            counts: time?.counts != null ? String(time?.counts) : ''
          }
        })
        setDayCount(formattedDayCount)
      }
      else{
        setDayCount(initialValueForDayCount)
      }
      if (editData?.hoursOfOperationList?.length) {
        const formattedHoursOfOperation = editData?.hoursOfOperationList?.map((time: any) => {
          return {
            ...time,
      
            hoursOfOperationOne: {
              ...time?.hoursOfOperationOne,
              startTime: time?.hoursOfOperationOne?.startTime ? convertToDayjsFormat(time?.hoursOfOperationOne?.startTime) : '',
              endTime: time?.hoursOfOperationOne?.endTime ? convertToDayjsFormat(time?.hoursOfOperationOne?.endTime) : ''
            },
            busiestDayOne: {
              ...time?.busiestDayOne,
              startTime: time?.busiestDayOne?.startTime ? convertToDayjsFormat(time?.busiestDayOne?.startTime) : '',
              endTime: time?.busiestDayOne?.endTime ? convertToDayjsFormat(time?.busiestDayOne?.endTime) : ''
            },
          };
        });
      
        setSchedule(formattedHoursOfOperation);
      } else {
        setSchedule(initialValueForSchedule);
      }

      formik.setFieldValue(
        "mobileNumber",
        editData?.countryCode + editData?.mobileNumber
      );
      if (imgUrl) {
        setGolfImg(imgUrl);
      }
    }
  }, [editData]);

  useEffect(() => {
    if (countryValue) {
      getStateData({ countryId: countryValue });
    }
    formik.setFieldValue('state', null);
    setSelectedState(null);
  }, [countryValue]);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue });
    }
    else{
      formik.setFieldValue('city', null);
      setSelectedCity(null)
    }
  }, [stateValue]);


  
  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setGolfImg(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  
  const checkDomainAvailability = async (domain:any, cateId:any) => {
    try {
      const response = await httpsClient.post(APP_URLs.check_domain.domain_avaliable, {
        domain,
        cateId
      });
      return response.data.available;
    } catch (error) {
      console.error("Error checking domain availability", error);
      return false;
    }
  };

  useEffect(() => {
    if(editData && countryData && stateData && cityData && !selectedState && !selectedCity && !selectedCountry){
      const filteredStateValue = stateData?.data?.filter((Id: any) => Id?.stateId === editData?.address?.stateId)
      const stateValue = {
        label: filteredStateValue[0]?.name,
        value: filteredStateValue[0]?.stateId
      }
      formik.setFieldValue('state', stateValue?.value);
      setSelectedState(stateValue)

      const filteredCityValue = cityData?.data?.filter((Id: any) => Id?.cityId === editData?.address?.cityId)
      const cityValue = {
        label: filteredCityValue[0]?.name,
        value: filteredCityValue[0]?.cityId
      }
      formik.setFieldValue('city', cityValue?.value); 
      setSelectedCity(cityValue)

      const filteredCountryValue = countryData?.data?.filter((Id: any) => Id?.countryId === editData?.address?.countryId)
      const countryValue = {
        label: filteredCountryValue[0]?.name,
        value: filteredCountryValue[0]?.countryId
      }
      formik.setFieldValue('country', countryValue?.value);
      setSelectedCountry(countryValue)
    }
  },[countryData, stateData, cityData, editData])

  const countryOptions = countryData?.data?.map((country: any) => ({
    value: country?.countryId,
    label: `${country?.name} (${country?.code})`,
  }));

  const stateOptions = stateData?.data?.map((state: any) => ({
    value: state?.stateId,
    label: `${state?.name}`,
  }));

  const cityOptions = cityData?.data?.map((city: any) => ({
    value: city?.cityId,
    label: `${city?.name}`,
  }));

  const updateSchedule = (day: any, type: any, value: any) => {
      setSchedule((prev: any) =>
        prev.map((item: any) =>
          item?.day === day
            ? {
                ...item,
                ...(type.startsWith('hoursOfOperationOne') && {
                  hoursOfOperationOne: {
                    ...item?.hoursOfOperationOne,
                    [type.split('.')[1]]: value,
                    ...(type.endsWith('startTime') && { endTime: '' }),
                  },
                }),
                ...(type.startsWith('busiestDayOne') && {
                  busiestDayOne: {
                    ...item?.busiestDayOne,
                    [type.split('.')[1]]: value,
                    ...(type.endsWith('startTime') && { endTime: '' }),
                  },
                }),
                ...(type === 'IsOpen' && { [type]: value }),
              }
            : item
        )
      );
  // }
  }

  const handleChipClick = (day: string) => {
    setSelectedDay(day)
  };
  
  const handleSameCountClick = (e: any, counts: any) => {
    const isChecked = e?.target?.checked;
    setIsChecked(isChecked)
    setDayCount((prev: any) => prev.map((item: any) => ({ ...item, ['counts']: counts })));
  }

  const handleCountChange = (type: any , value: any) => {
    if(value >= 0){
    setDayCount((prev: any) => 
      prev.map((item: any) =>
        item?.day === selectedDay ? { ...item, [type]: value } : item
      )
    );
    setIsChecked(false)
  }
  }

  const handleRemoveImage = (type: any) => {
    if(type == "websitelogo"){
      setImgFileName("")
      setLogoFileName("")
      setGolfImg("")
    }
    else if(type == "websiteimage"){
      setWebsiteFileName("")
      setGolfImg("")
    }
  }

  const handleChangeImageUploadLogo = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setLogoFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setLogoFileName(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  //web image
  const handleChangeImageUploadImage = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setWebsiteFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setWebsiteFileName(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };
  
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => {
        setImgFileName("");
        setGolfImg("");
        handleClose(false);
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData?._id ? "Edit" : "Add"} Hotel</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            setImgFileName("");
            setGolfImg("");
            handleClose(false);
          }}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6" style={{ height: "80vh", overflowY: "auto" }}>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Mobile Number{''} <span className="required-icon">*</span></label>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  masks={{
                    us: "...-...-....",
                    in: "...-...-....",
                  }}
                  // variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  onChange={(value: string, country: any) => {
                    formik.setFieldValue(
                      "countryCode",
                      country?.dialCode || ""
                    );
                    formik.setFieldValue("mobileNumber", value || "");
                  }}
                  inputStyle={{
                    background: "#f9f9f9",
                    border: "none",
                  }}
                  inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.mobileNumber}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={12}>
                <label className="form-label fw-bold">Description{''} <span className="required-icon">*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditHotel.modules}
                  formats={AddEditHotel.formats}
                  value={formik.values.description}
                  onChange={(content) =>
                    formik.setFieldValue("description", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}
            <Row
              className={`${formik.touched.rulesDescription &&
                formik.errors.rulesDescription
                ? "pt-6"
                : "pt-17"
                }`}
            >
              <Col xs={6}>
                <label className="form-label fw-bold">Latitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Latitude"
                  aria-label="Latitude"
                  {...formik.getFieldProps("latitude")}
                />
                {formik.touched.latitude && formik.errors.latitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.latitude}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6}>
                <label className="form-label fw-bold">Longitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Longitude"
                  aria-label="Longitude"
                  {...formik.getFieldProps("longitude")}
                />
                {formik.touched.longitude && formik.errors.longitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.longitude}
                    </div>
                  </div>
                )}
              </Col>

              
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Booking Preferences{''} <span className="required-icon">*</span></label>
                <div className="fv-row d-flex">
                  <label
                    className="form-check form-check-inline"
                    style={{ paddingTop: 7 }}
                  >
                    Squeez
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) =>  formik.setFieldValue('isSqueezOpen', e.target.checked)}
                      checked={formik.values.isSqueezOpen}
                    />
                  </label>

                  <label
                    className="form-check form-check-inline"
                    style={{ paddingTop: 7 }}
                  >
                    Waitlist
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) => formik.setFieldValue('isWaitListOpen', e.target.checked)}
                      checked={formik.values.isWaitListOpen}
                    />
                  </label>
                </div>
                {formik.touched.isWaitListOpen && formik.errors.isWaitListOpen && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.isWaitListOpen}
                        </div>
                      </div>
                    )}
              </Col>
            </Row>
            <Row className="mt-4 highlight-section">
            <Col xs={12}>
            <label className="col-lg-4 col-form-label fw-bold fs-6">
              <span>Address</span>
            </label>
            </Col> 
            <Col xs={6}>
                <label className="form-label fw-bold">Address Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("addressName")}
                />
                {formik.touched.addressName && formik.errors.addressName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.addressName}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6}>
                <label className="form-label fw-bold">Street{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Street"
                  aria-label="Street"
                  {...formik.getFieldProps("street")}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.street}
                    </div>
                  </div>
                )}
              </Col>

              {/* <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Unit{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Unit"
                  aria-label="Unit"
                  {...formik.getFieldProps("unit")}
                />
                {formik.touched.unit && formik.errors.unit && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.unit}
                    </div>
                  </div>
                )}
              </Col> */}

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Country{''} <span className="required-icon">*</span></label>
<Select
                  isDisabled={isLoadingCountry}
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('country', selectedOption?.value);
                    setSelectedCountry(selectedOption);
                  }}
                  placeholder="Select Country"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.country}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">State{''} <span className="required-icon">*</span></label>
<Select
                  isDisabled={isLoadingState}
                  options={stateOptions}  // Pass the options for react-select (state data)
                  value={selectedState}  // Set the selected value
                  onChange={(selectedOption: any) => 
                    {
                     formik.setFieldValue('state', selectedOption?.value);
                     setSelectedState(selectedOption);
                    }} // Update Formik on selection
                  placeholder="Select State"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.state}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">City{''} <span className="required-icon">*</span></label>
<Select
                  isDisabled={isLoadingCity}
                  options={cityOptions}
                  value={selectedCity}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('city', selectedOption?.value);
                    setSelectedCity(selectedOption);
                  }}
                  placeholder="Select City"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.city}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Zip{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Zip"
                  aria-label="Zip"
                  {...formik.getFieldProps("zip")}
                />
                {formik.touched.zip && formik.errors.zip && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.zip}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Booking URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Booking URL"
                  aria-label="Booking URL"
                  {...formik.getFieldProps("websiteURL")}
                />
                {formik.touched.websiteURL && formik.errors.websiteURL && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.websiteURL}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Email</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Email"
                  aria-label="Email"
                  {...formik.getFieldProps("emailAddress")}
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.emailAddress}
                    </div>
                  </div>
                )}
              </Col>
              </Row>
              <Row>
              {/* <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Type</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Type"
                  aria-label="Type"
                  {...formik.getFieldProps("restaurantType")}
                />
                {formik.touched.restaurantType && formik.errors.restaurantType && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.restaurantType}
                    </div>
                  </div>
                )}
              </Col> */}
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Tax (%)</label>
                <AmountInput
                  placeholder="Tax"
                  formikProps={{ ...formik.getFieldProps("tax") }}
                  handleChange={(e) => formik.handleChange(e)}
                  isPercentage={true}
                />
                {/* {formik.touched.tax && formik.errors.tax && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.tax}</div>
                  </div>
                )} */}
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">
                  Convenience Fee (Amount)
                </label>
                <AmountInput
                  placeholder="Convenience Fee"
                  formikProps={{ ...formik.getFieldProps("convenienceFee") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {/* {formik.touched.convenienceFee && formik.errors.convenienceFee && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.convenienceFee}
                    </div>
                  </div>
                )} */}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Hotel Menu</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Hotel Menu URL"
                  aria-label="Hotel Menu URL"
                  {...formik.getFieldProps("menuUrl")}
                />
                {formik.touched.menuUrl && formik.errors.menuUrl && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.menuUrl}</div>
                  </div>
                )}
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Image upload</label>
                <div>
                  <label
                    htmlFor="golf-img"
                    className="form-control form-control-lg form-control-solid mb-0 mb-lg-0 cursor-pointer"
                  >
                    {imgFileName
                      ? charValidate(imgFileName, 25)
                      : "Choose File"}
                  </label>
                  <input
                    id="golf-img"
                    type="file"
                    accept="image/*"
                    className="form-control form-control-lg form-control-solid mb-0 mb-lg-0"
                    placeholder="Upload Image"
                    aria-label="Upload Image"
                    onChange={handleChangeImageUpload}
                    style={{
                      display: "none",
                    }}
                  />
                </div>
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Facebook Url</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Facebook Url"
                  aria-label="Facebook Url"
                  {...formik.getFieldProps("facebookUrl")}
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Instagram URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Instagram Url"
                  aria-label="Instagram Url"
                  {...formik.getFieldProps("instagramUrl")}
                />
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Twitter URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Twitter Url"
                  aria-label="Twitter Url"
                  {...formik.getFieldProps("twitterUrl")}
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Award Winning</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Award Winning"
                  aria-label="Type"
                  {...formik.getFieldProps("awardWinning")}
                />
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Price</label>
                <AmountInput
                  placeholder="Price"
                  formikProps={{ ...formik.getFieldProps("price") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Cuisine</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Cuisine"
                  aria-label="Type"
                  {...formik.getFieldProps("cuisine")}
                />
              </Col>

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Payment Options</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Payment Options"
                  aria-label="Type"
                  {...formik.getFieldProps("paymentOptions")}
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Hotel Website</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Website"
                  aria-label="Type"
                  {...formik.getFieldProps("restaurantUrl")}
                />
              </Col>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Yelp Review</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Yelp Review"
                  aria-label="yelpReview"
                  {...formik.getFieldProps("yelpReview")}
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">
                  Trip Advisor Review
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Trip Advisor Review"
                  aria-label="tripAdvisorReview"
                  {...formik.getFieldProps("tripAdvisorReview")}
                />
              </Col>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Eater Review</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Eater Review"
                  aria-label="eaterReview"
                  {...formik.getFieldProps("eaterReview")}
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Google Review</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Google Review"
                  aria-label="googleReview"
                  {...formik.getFieldProps("googleReview")}
                />
              </Col>

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Reservation Link</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Reservation Link"
                  aria-label="reservationLink"
                  {...formik.getFieldProps("reservationLink")}
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Minimum Threshold Amount <span className="required-icon">*</span></label>
                <AmountInput
                  placeholder="Minimum Threshold Amount"
                  formikProps={{ ...formik.getFieldProps("minThreshold") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {formik.touched.minThreshold && formik.errors.minThreshold && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.minThreshold}
                    </div>
                  </div>
                )}
              </Col>
              
              <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Valet Parking</label>
                  <Form.Check
                    type="switch"
                    checked={formik.values.IsParkingOpen}
                    id="custom-switch"
                    {...formik.getFieldProps("IsParkingOpen")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Auto - Approved Amount (per person) <span className="required-icon">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Auto - Approved Amount (per person)"
                    {...formik.getFieldProps("minSqueezAmount")}
                  />
                  {formik.touched.minSqueezAmount &&
                    formik.errors.minSqueezAmount && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.minSqueezAmount}
                        </div>
                      </div>
                    )}
                </Col>

                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Auto Approve</label>

                  <Form.Check
                    type="switch"
                    checked={formik.values.IsAutoApprove}
                    id="custom-switch"
                    {...formik.getFieldProps("IsAutoApprove")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>

              </Row>

              <Row>
              <Col xs={6} className="pt-6">
              <label className="form-label fw-bold">Maximum Person{''} <span className="required-icon">*</span></label>
                <AmountInput
                  placeholder="Please enter the person count"
                  formikProps={{ ...formik.getFieldProps("personCount") }}
                  handleChange={(e) => formik.handleChange(e)}
                  isShowIcon={false}
                />
                {formik.touched.personCount &&
                    formik.errors.personCount && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.personCount}
                        </div>
                      </div>
                    )}
              </Col>

              <Col xs={6} className="pt-6">
              <label className="form-label fw-bold">Maximum Price{''} <span className="required-icon">*</span></label>
                <AmountInput
                  placeholder="Please enter the maximum price"
                  formikProps={{ ...formik.getFieldProps("maximumPrice") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {formik.touched.maximumPrice &&
                    formik.errors.maximumPrice && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.maximumPrice}
                        </div>
                      </div>
                    )}
              </Col>
              </Row>

              <Row className="highlight-section mt-4">
                <Col xs={12} className="pt-6">
                  <label className="form-label fw-bold">
                    Allocation
                  </label>
                  <Stack direction="row" spacing={1}>
                    {dayCount?.map((dayData: any) => (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Chip
                          key={dayData?.day}
                          label={dayData?.day}
                          variant={selectedDay === dayData.day ? "filled" : "outlined"}
                          color={selectedDay === dayData.day ? "primary" : "default"}
                          onClick={() => handleChipClick(dayData.day)}
                          className="mb-2"
                        />
                      </div>
                    ))}
                  </Stack>
                </Col>
                <Col xs={8}>
                  {dayCount?.map((dayData: any) => (
                    <>
                    <div style={{ display: "flex", flexDirection: "column", width:'94%' }}>
                      {dayData.day === selectedDay && (
                        <input
                          type="number"
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          placeholder={`Please enter the allocation for ${selectedDay}`}
                          value={dayData?.counts}
                          min="0"
                          onChange={(e: any) => handleCountChange('counts', e.target.value)}
                        />
                      )}
                    </div>
                    {dayData.day === selectedDay && (
                      <div className="mt-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="count-checkbox"
                      checked={isChecked}
                      onChange={(e) => handleSameCountClick(e, dayData?.counts)}
                    />{" "}
                <label className="fw-bold mt-1" style={{ fontSize:'12px' }}>Select the checkbox to pass the same allocation for all days</label>
                </div> 
                    )}
                </>
              ))}
                </Col>
                <Col xs={12}>
                  <div className="card p-3 mb-4 shadow-sm border-light mt-5 w-100">
                    <div className="d-flex align-items-start">
                      <i className="bi bi-info-circle-fill fs-4 text-info me-3"></i>
                      <div style={{ maxHeight: '20px' }}>
                        <h5 className="card-title">
                          An empty field should be considered as an unlimited Squeez Request.
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="pt-6">
                <Col xs={12}>
                  <label className="form-label fw-bold">
                    Hours Of Operation
                  </label>
                 {schedule?.map((day: any) => (
                                     <Accordion key={day?.day} expanded={day?.IsOpen} onChange={() => updateSchedule(day?.day, 'IsOpen', !day?.IsOpen)}>
                                       <AccordionSummary
                                         expandIcon={<ExpandMore />}
                                         aria-controls={`${day?.day}-content`}
                                         id={`${day?.day}-header`}
                                         onClick={() => updateSchedule(day?.day, 'IsOpen', !day?.IsOpen)}
                                       >
                                         <Typography className="text-capitalize">{day?.day}</Typography>
                                         <FormControlLabel
                                           control={
                                             <Switch
                                               checked={day?.IsOpen}
                                               onChange={(e) => updateSchedule(day?.day, 'IsOpen', e.target.checked)}
                                               color="primary"
                                             />
                                           }
                                           label={
                                             <span style={{ minWidth: 40, display: 'inline-block' }}>
                                               {day?.IsOpen ? "Open" : "Closed"}
                                             </span>
                                           }
                                           onClick={(e) => e.stopPropagation()}
                                           onFocus={(e) => e.stopPropagation()}
                                           className="ms-3"
                                         />
                                       </AccordionSummary>
                                       <AccordionDetails>
                                         <Box className="row">
                                         <div className="d-flex flex-row">
                                             {/* <Typography variant="subtitle1" gutterBottom>Lunch Hours</Typography> */}
                                             <div className="w-100">
                                             <input
                                             placeholder="Please enter the title"
                                             onChange={(e: any) => updateSchedule(day?.day, 'hoursOfOperationOne.name', e?.target?.value)}
                                             value={day?.hoursOfOperationOne?.name}
                                             disabled={!day?.IsOpen}
                                             style={{
                                              backgroundColor: !day?.IsOpen ? "#e5e5e5" : ""
                                            }}
                                             className="p-4 input-border form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                             />
                             {day?.IsOpen && !day?.hoursOfOperationOne?.name  && (day?.hoursOfOperationOne?.startTime || day?.hoursOfOperationOne?.endTime) && (
                               <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                 Title is required
                               </label>
                             )}
                             </div>
                                             <Typography className="mx-2 fw-bold mt-4">:</Typography>
                                             <div className="w-100">
                                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                 <TimePicker
                                                   ampm={false}
                                                   timeSteps={{ minutes: 1 }}
                                                   onChange={(newValue: any) => updateSchedule(day?.day, 'hoursOfOperationOne.startTime', newValue)}
                                                   value={day?.hoursOfOperationOne?.startTime}
                                                   disabled={!day?.IsOpen}
                                                   sx={{
                                                     width: "100%",
                                                     "& .MuiOutlinedInput-input": { py: 1.35 },
                                                   }}
                                                   slotProps={{
                                                     layout: {
                                                       sx: {
                                                         [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                                           display: "none"
                                                         }
                                                       }
                                                     }
                                                   }}
                                                 />
                                               </LocalizationProvider>
                               {day?.IsOpen && !day?.hoursOfOperationOne?.startTime && (day?.hoursOfOperationOne?.name || day?.hoursOfOperationOne?.endTime) && (
                                 <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                   Start time is required
                                 </label>
                               )}
                               </div>
                                               <Typography className="mx-2 mt-4">-</Typography>
                                               <div className="w-100">
                                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                 <TimePicker
                                                   ampm={false}
                                                   timeSteps={{ minutes: 1 }}
                                                   onChange={(newValue: any) => updateSchedule(day?.day, 'hoursOfOperationOne.endTime', newValue)}
                                                   value={day?.hoursOfOperationOne?.endTime}
                                                   disabled={!day?.IsOpen || !day?.hoursOfOperationOne?.startTime}
                                                   minTime={day?.hoursOfOperationOne?.startTime ? day?.hoursOfOperationOne?.startTime : undefined}
                                                   sx={{
                                                     width: "100%",
                                                     "& .MuiOutlinedInput-input": { py: 1.35 },
                                                   }}
                                                   slotProps={{
                                                     layout: {
                                                       sx: {
                                                         [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                                           display: "none"
                                                         }
                                                       }
                                                     }
                                                   }}
                                                 />
                                               </LocalizationProvider>
                               {day?.IsOpen && !day?.hoursOfOperationOne?.endTime && (day?.hoursOfOperationOne?.name || day?.hoursOfOperationOne?.startTime) && (
                                 <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                   End time is required
                                 </label>
                               )}
                               </div>
                                             </div>
                                           </Box>
                                         <Box className="row mt-4">
                                           <Box className="col-md-6 mb-3">
                                             <div className="d-flex align-items-center justify-content-center"><Typography variant="subtitle1" className="fw-bold" gutterBottom>Busiest Time</Typography></div>
                                             <div className="d-flex flex-row">
                                              <div className="w-100">
                                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                 <TimePicker
                                                   ampm={false}
                                                   timeSteps={{ minutes: 1 }}
                                                   onChange={(newValue: any) => updateSchedule(day?.day, 'busiestDayOne.startTime', newValue)}
                                                   value={day?.busiestDayOne?.startTime}
                                                   disabled={!day?.IsOpen}
                                                   sx={{
                                                     width: "100%",
                                                     "& .MuiOutlinedInput-input": { py: 1.35 },
                                                   }}
                                                   slotProps={{
                                                     layout: {
                                                       sx: {
                                                         [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                                           display: "none"
                                                         }
                                                       }
                                                     }
                                                   }}
                                                 />
                                               </LocalizationProvider>
                             {day?.IsOpen && !day?.busiestDayOne?.startTime && day?.busiestDayOne?.endTime && (
                               <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                 Start time is required
                               </label>
                             )}
                             </div>
                                               <Typography className="mx-2 mt-4">-</Typography>
                                               <div className="w-100">
                                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                 <TimePicker
                                                   ampm={false}
                                                   timeSteps={{ minutes: 1 }}
                                                   onChange={(newValue: any) => updateSchedule(day?.day, 'busiestDayOne.endTime', newValue)}
                                                   value={day?.busiestDayOne?.endTime}
                                                   disabled={!day?.IsOpen || !day?.busiestDayOne?.startTime}
                                                   minTime={day?.busiestDayOne?.startTime ? day?.busiestDayOne?.startTime : undefined}
                                                   sx={{
                                                     width: "100%",
                                                     "& .MuiOutlinedInput-input": { py: 1.35 },
                                                   }}
                                                   slotProps={{
                                                     layout: {
                                                       sx: {
                                                         [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                                           display: "none"
                                                         }
                                                       }
                                                     }
                                                   }}
                                                 />
                                               </LocalizationProvider>
                             {day?.IsOpen && !day?.busiestDayOne?.endTime && day?.busiestDayOne?.startTime && (
                               <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                                 End time is required
                               </label>
                             )}
                             </div>
                             </div>
                                             </Box>
                                           </Box>
                                       </AccordionDetails>
                                     </Accordion>
                                   ))}
                </Col>
              </Row>

              {/* <Col  xs={6} className="pt-6">
                <label className="form-label fw-bold">Parking</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Parking"
                  aria-label="Type"
                  {...formik.getFieldProps("parking")}
                />
              </Col> */}

              <Row className="pt-8">
                <Col xs={12}>
                  <label className="form-label fw-bold">Safety</label>
                  <ReactQuill
                    theme="snow"
                    placeholder="Write something..."
                    modules={AddEditHotel.modules}
                    formats={AddEditHotel.formats}
                    value={formik.values.safety}
                    onChange={(content) =>
                      formik.setFieldValue("safety", content)
                    }
                    style={{
                      height: "12rem",
                    }}
                  />
                </Col>
              </Row>
              
              <Row
                // className={`${formik.touched.rulesDescription &&
                //   formik.errors.rulesDescription
                //   ? "pt-6"
                //   : "pt-17"
                //   }`}
                className="pt-3 highlight-section mt-20"
              >
                <Col xs={6}>
                  <label className="form-label fw-bold">Domain URL{''} <span className="required-icon">*</span></label>
                  <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Domain URL"
                    {...formik.getFieldProps("domain")}
                    style={{ flex: '1' }}
                  />
                  <span className="form-label fw-bold ">.trysqueez.com</span>
                  </div>
                  {formik.touched.domain && formik.errors.domain && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.domain}</div>
                    </div>
                  )}
                  {isCheckingDomain && <p>Checking domain availability...</p>}
                </Col>

                <Col xs={6}>
                  <label className="form-label fw-bold">Primary Color</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      style={{ width: "80px", height: "50px", padding: "0", border: "none" }}
                      value={formik.values.primaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("primaryColor", e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control ms-3"
                      placeholder="#323232"
                      value={formik.values.primaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("primaryColor", e.target.value);
                      }}
                    />
                  </div>
                  {formik.touched.primaryColor &&
                    formik.errors.primaryColor && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.primaryColor}
                        </div>
                      </div>
                    )}
                </Col>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Secondary Color</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      style={{ width: "80px", height: "50px", padding: "0", border: "none" }}
                      value={formik.values.secondaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("secondaryColor", e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control ms-3"
                      placeholder="#323232"
                      value={formik.values.secondaryColor}
                      onChange={(e) => {
                        formik.setFieldValue("secondaryColor", e.target.value);
                      }}
                    />
                  </div>
                  {formik.touched.secondaryColor &&
                    formik.errors.secondaryColor && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.secondaryColor}
                        </div>
                      </div>
                    )}
                </Col>

                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Light Color</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      style={{ width: "80px", height: "50px", padding: "0", border: "none" }}
                      value={formik.values.lightColor}
                      onChange={(e) => {
                        formik.setFieldValue("lightColor", e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control ms-3"
                      placeholder="#323232"
                      value={formik.values.lightColor}
                      onChange={(e) => {
                        formik.setFieldValue("lightColor", e.target.value);
                      }}
                    />
                  </div>
                  {formik.touched.lightColor &&
                    formik.errors.lightColor && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.lightColor}
                        </div>
                      </div>
                    )}
                </Col>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Website Logo</label>
                  <div>
                  {logoFileName != "" ? (
                   <div style={{ position: "relative", display: "inline-block", backgroundColor:"#f9f9f9", width:"100%", borderRadius:"5px"}}>
                   <p style={{ display: "inline-block", marginRight: "30px", marginTop:"12px", paddingLeft:"13px" }}>{charValidate(logoFileName, 35)}</p>
                   <CancelIcon
                     onClick={() => handleRemoveImage("websitelogo")}
                     className="file-remove-icon"
                   />
                 </div>
                  ):(
                    <>
                    <label
                    htmlFor="logo-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                    >
                    {logoFileName
                    ? charValidate(logoFileName, 25)
                    : "Choose File"}
                    </label>

                
                    <input
                      id="logo-img"
                      type="file"
                      accept="image/*"
                      className="form-control form-control-lg form-control-solid mb-0 mb-lg-0"
                      placeholder="Upload Image"
                      aria-label="Upload Image"
                      onChange={handleChangeImageUploadLogo}
                      style={{
                        display: "none",
                      }}
                    />
                    </>
                  )}
                  </div>
                </Col>

                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Website Image</label>
                  <div>
                  {websiteFileName != "" ? (
                   <div style={{ position: "relative", display: "inline-block", backgroundColor:"#f9f9f9", width:"100%", borderRadius:"5px"}}>
                   <p style={{ display: "inline-block", marginRight: "30px", marginTop:"12px", paddingLeft:"13px" }}>{charValidate(websiteFileName, 35)}</p>
                   <CancelIcon
                     onClick={() => handleRemoveImage("websiteimage")}
                     className="file-remove-icon"
                   />
                 </div>
                  ):(
                    <>
                    <label
                    htmlFor="website-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                    >
                    {websiteFileName
                    ? charValidate(websiteFileName, 25)
                    : "Choose File"}
                    </label>
                    <input
                      id="website-img"
                      type="file"
                      accept="image/*"
                      className="form-control form-control-lg form-control-solid mb-0 mb-lg-0"
                      placeholder="Upload Image"
                      aria-label="Upload Image"
                      onChange={handleChangeImageUploadImage}
                      style={{
                        display: "none",
                      }}
                    />
                    </>
                  )}
                  </div>
                </Col>
              </Row>

          </Container>
          <div className="w-100 d-flex align-items-center justify-content-end mt-15">
            <Button
              onClick={() => {
                formik.resetForm();
                setImgFileName("");
                setGolfImg("");
                handleClose(false);
              }}
              style={{ marginRight: "5px" }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" size="lg" variant="primary">
              {isAddingGolf ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

AddEditHotel.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditHotel.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default AddEditHotel;
